import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// components
import UserSetting from '../../components/settingPage/UserSetting';
import CompanySetting from '../../components/settingPage/CompanySetting';
import TSBSetting from './TSB_Setting';

// contexts
import { useAuth } from '../../contexts/AuthProvider.js';

// css
import '../../styles/Setting.css';

// img
import Left from '../../assets/arrow_left.png';

function SettingPage() {
  const { user } = useAuth();

  // 페이지 맨 위로 스크롤
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  const menuList = {
    0: <UserSetting />,
    1: <CompanySetting />,
  };
  const TabData = [
    { id: 0, button: '기본 정보', content: menuList[0] },
    { id: 1, button: 'TSB 설정', content: menuList[1] },
  ];
  const [activeTab, setActiveTab] = useState(0);

  if (TabData[activeTab].id === 1) {
    return (
      <div>
        {/* Nav */}
        <div className="nav">
          <div className="nav_container">
            <a href="/" onClick={handleClick}>
              <img className="left_icon" src={Left} alt="아이콘 이미지" />
            </a>
            <h1>설정</h1>
            <div style={{ width: '30px', height: '30px' }}></div>
          </div>
        </div>

        <div className="setting_wrap">
          <div className="setting_tab d-flex">
            {TabData.map(tab => (
              <div
                key={tab.id}
                className={`setting_tab_box ${activeTab === tab.id ? 'active' : ''}`}
                onClick={() => setActiveTab(tab.id)}
              >
                {tab.button}
              </div>
            ))}
          </div>

          <div className="setting_container">{TabData[activeTab].content}</div>

          <div className="line"></div>

          <TSBSetting />
        </div>
      </div>
    );
  }

  if (user.role === '관리자') {
    return (
      <div>
        {/* Nav */}
        <div className="nav">
          <div className="nav_container">
            <Link to="/" onClick={handleClick}>
              <img className="left_icon" src={Left} alt="아이콘 이미지" />
            </Link>
            <h1>설정</h1>
            <div style={{ width: '30px', height: '30px' }}></div>
          </div>
        </div>

        <div className="setting_wrap">
          <div className="setting_tab d-flex">
            {TabData.map(tab => (
              <div
                key={tab.id}
                className={`setting_tab_box ${activeTab === tab.id ? 'active' : ''}`}
                onClick={() => setActiveTab(tab.id)}
              >
                {tab.button}
              </div>
            ))}
          </div>

          <div className="setting_container">{TabData[activeTab].content}</div>
        </div>
      </div>
    );
  }

  if (user.role === '사용자') {
    return (
      <div>
        {/* Nav */}
        <div className="nav">
          <div className="nav_container">
            <Link to="/" onClick={handleClick}>
              <img className="left_icon" src={Left} alt="아이콘 이미지" />
            </Link>
            <h1>설정</h1>
            <div style={{ width: '30px', height: '30px' }}></div>
          </div>
        </div>
        <div className="setting_wrap">
          <div className="setting_tab d-flex">{TabData.id}</div>
          <div className="setting_container">{TabData[activeTab].content}</div>
        </div>
      </div>
    );
  }
}
export default SettingPage;
