import React, { useState, forwardRef, useRef, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthProvider';
import { passwordValid } from '../../utils/passwordValid';
import { updateUserInfo, userSelectSub } from '../../apis/users_api';

// img
import On from '../../assets/on.png';
import Off from '../../assets/off.png';

const UserSetting = forwardRef((props, ref) => {
  const { user } = useAuth();
  const [userInfo, setUserInfo] = useState({});
  const { id, user_name, user_email, user_company, user_department } = userInfo[0]
    ? userInfo[0]
    : user.result;

  const passwordInputRef = useRef();
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isBtnClick, setIsBtnClick] = useState(false);

  const [mailTime, setMailTime] = useState(user.mail_receive_time);

  const mailCheckInputRef = useRef();
  const [mailCheck, setMailCheck] = useState(user.is_mail_received);

  const nameInputRef = useRef();
  const [name, setName] = useState(user_name);

  const companyInputRef = useRef();
  const [company, setCompany] = useState(user_company);

  const departmentInputRef = useRef();
  const [department, setDepartment] = useState(user_department);

  // 메일시간이 널값이면 기본시간(11:00) 설정
  if (!mailTime) {
    setMailTime('11:00');
  }

  const handlePasswordChange = event => {
    const inputPassword = event.target.value;
    setPassword(inputPassword);
  };

  const toggleShowPassword = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };

  const handlePasswordCheckBtn = () => {
    const isValidPassword = passwordValid(password);
    setIsPasswordValid(isValidPassword);
    setIsBtnClick(true);
  };

  const handleNameChange = event => {
    const inputName = event.target.value;
    setName(inputName);
  };

  const handleCompanyChange = event => {
    const inputCompany = event.target.value;
    setCompany(inputCompany);
  };

  const handleDepartmentChange = event => {
    const inputDepartment = event.target.value;
    setDepartment(inputDepartment);
  };

  const handleEditBtnClick = async () => {
    if (!name) {
      alert('성명은 필수 항목 입니다.');
      return;
    }

    if (!company) {
      alert('소속 회사명은 필수 항목 입니다.');
      return;
    }

    if (!department) {
      alert('소속 부서명은 필수 항목 입니다.');
      return;
    }

    if (!isPasswordValid && password) {
      alert('비밀번호 형식이 올바르지 않습니다.');
      return;
    }

    const options = {
      user_name: name,
      user_company: company,
      user_department: department,
      user_role: user.role,
      mail_receive_time: mailTime,
      is_mail_received: mailCheck,
    };

    if (password !== null && password !== undefined && password !== '') {
      options.user_pw = password;
    }

    try {
      await updateUserInfo(id, options);
      alert('입력하신 개인정보가 변경되었습니다.');
      window.location.href = '/';
    } catch (error) {
      console.error('사용자 정보 업데이트 오류:', error);
      alert('사용자 정보 업데이트 중 오류가 발생했습니다.');
    }
  };

  const handleMailTimeChange = event => {
    setMailTime(event.target.value);
  };

  const handleMailCheckboxChange = event => {
    const isChecked = event.target.checked;
    const value = isChecked ? 'Y' : 'N';
    setMailCheck(value);
  };

  useEffect(() => {
    // console.log(user.role);
    if (user.role === '사용자') {
      const options = { input_name: 'email', user_email: user.login_user };
      const fetchData = async () => {
        try {
          const userData = await userSelectSub(options);
          // console.log(userData[0].user_name);
          setName(userData[0].user_name); // 자식 데이터 가져와서 바로 화면에 보이게 초기값으로
          setDepartment(userData[0].user_department); // 자식 데이터 가져와서 바로 화면에 보이게 초기값으로
          setUserInfo(userData);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };

      fetchData();
    } else {
      setUserInfo(user.result);
    }
  }, [user.role, user.result, user.login_user]);

  return (
    <div>
      <div className="setting_intro_title">
        <h1 className="label1 secondary">개인 정보</h1>
        <p className="body2 secondary">비밀번호 및 개인정보를 변경할 수 있습니다.</p>
      </div>

      <div className="keyword_box">
        <p className="label2">이메일 계정</p>
        <input
          type="text"
          id="disabledInput"
          value={user_email || ''}
          readOnly
          disabled
          style={{ background: '#EFEFEF' }}
        />
      </div>

      <div className="keyword_box">
        <p className="label2">비밀번호</p>
        <div className="pass_input d-between">
          <input
            type={showPassword ? 'text' : 'password'}
            id="passwordInput"
            value={password || ''}
            onChange={handlePasswordChange}
            ref={passwordInputRef}
          />
          <button onClick={toggleShowPassword} className="btn_off">
            <img
              src={showPassword ? On : Off}
              alt={showPassword ? '비밀번호 보기' : '비밀번호 숨기기'}
            />
          </button>
          <button onClick={handlePasswordCheckBtn} className="pass_btn label1">
            변경
          </button>
        </div>
        <div className="label2">
          {isBtnClick && !isPasswordValid ? (
            <p className="point">
              비밀번호는 최소 8자 이상, 영문 대소문자, 숫자, 특수문자를
              <br />
              각각 1개 이상 포함해야 합니다.
            </p>
          ) : (
            isBtnClick && <p className="primary">사용할 수 있는 비밀번호입니다.</p>
          )}
        </div>
      </div>

      <div className="keyword_box">
        <p className="label2">
          성명 <span className="point">*</span>
        </p>
        <input id="name" value={name || ''} onChange={handleNameChange} ref={nameInputRef} />
      </div>

      <div className="keyword_box">
        <p className="label2">
          소속 회사 <span className="point">*</span>
        </p>
        <input
          id="company"
          value={company || ''}
          onChange={handleCompanyChange}
          ref={companyInputRef}
          disabled={user.role === '사용자'}
          style={{ background: user.role === '사용자' ? '#EFEFEF' : 'inherit' }}
        />
      </div>

      <div className="keyword_box">
        <p className="label2">
          소속 부서 <span className="point">*</span>
        </p>
        <input
          id="department"
          value={department || ''}
          onChange={handleDepartmentChange}
          ref={departmentInputRef}
        />
      </div>

      <div className="keyword_box">
        <p className="label2 gray2">[선택] TSB 정보 수신 알림</p>
        <div className="d-flex" style={{ marginBottom: '5px' }}>
          <input
            className="emailCheck"
            type="checkbox"
            id="emailCheck"
            checked={mailCheck === 'Y'}
            ref={mailCheckInputRef}
            onChange={handleMailCheckboxChange}
          />
          <label for="emailCheck" className="body3 gray2">
            TSB에서 제공하는 소식을 이메일로 받아 보시겠습니까?
          </label>
        </div>
        {mailCheck === 'Y' ? (
          <label className="body3 gray2" for="timeSelect">
            - 메일을{' '}
            <select id="timeSelect" name="time" value={mailTime} onChange={handleMailTimeChange}>
              <option value="10:00">10:00</option>
              <option value="11:00">11:00</option>
              <option value="12:00">12:00</option>
              <option value="13:00">13:00</option>
              <option value="14:00">14:00</option>
              <option value="15:00">15:00</option>
            </select>
            &nbsp;시에 받을게요.
          </label>
        ) : null}
      </div>

      <div className="setting_btn d-center">
        <button onClick={handleEditBtnClick} className="label1">
          설정 저장
        </button>
      </div>
    </div>
  );
});

export default UserSetting;
