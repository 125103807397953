import { BASE_URL } from '../config/base_url';
/* ─────────────────────────────────────────────────────────────
 * tsb_setting_api
    셋팅페이지 공통 api
 * ─────────────────────────────────────────────────────────────
 */

// 뉴스
export async function news_keywords(type, keywordValue, id, removedKeyword) {
  const token = localStorage.getItem('token');

  let bodyContent = {};
  type === 'domestic'
    ? (bodyContent = { news_keywords_domestic: keywordValue })
    : (bodyContent = { news_keywords_overseas: keywordValue });

  if (removedKeyword) {
    bodyContent = {
      ...bodyContent, // 기존의 bodyContent 속성을 그대로 유지
      remove_keyword: 'true',
    };
  }

  try {
    const response = await fetch(`${BASE_URL}/tsb-user/user-update/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(bodyContent),
    });

    if (!response.ok) {
      throw new Error('토큰이 유효하지 않습니다.');
    }

    const body = await response.json();
    return body;
  } catch (error) {
    console.error('Error fetching user info:', error);
    return null;
  }
}

//Market
export async function market_update(options = {}) {
  const { id, keywordValue, removedKeyword } = options;
  const token = localStorage.getItem('token');
  let bodyContent = {};
  bodyContent = { market_keywords: keywordValue };

  if (removedKeyword) {
    bodyContent = {
      ...bodyContent, // 기존의 bodyContent 속성을 그대로 유지
      remove_keyword: 'true',
    };
  }

  try {
    const response = await fetch(`${BASE_URL}/tsb-user/user-update/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(bodyContent),
    });

    if (!response.ok) {
      throw new Error('토큰이 유효하지 않습니다.');
    }

    const body = await response.json();
    return body;
  } catch (error) {
    console.error('Error fetching user info:', error);
    return null;
  }
}

//Invest
export async function invest_update(options = {}) {
  const { id, keywordValue, removedKeyword } = options;
  const token = localStorage.getItem('token');
  let bodyContent = {};
  bodyContent = { invests_keywords: keywordValue };

  if (removedKeyword) {
    bodyContent = {
      ...bodyContent, // 기존의 bodyContent 속성을 그대로 유지
      remove_keyword: 'true',
    };
  }

  try {
    const response = await fetch(`${BASE_URL}/tsb-user/user-update/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(bodyContent),
    });

    if (!response.ok) {
      throw new Error('토큰이 유효하지 않습니다.');
    }

    const body = await response.json();
    return body;
  } catch (error) {
    console.error('Error fetching user info:', error);
    return null;
  }
}

// 법규
export async function laws_keywords(type, keywordValue, id, removedKeyword) {
  const token = localStorage.getItem('token');

  let bodyContent = {};
  type === 'domestic'
    ? (bodyContent = { laws_keywords_domestic: keywordValue })
    : (bodyContent = { laws_keywords_overseas: keywordValue });

  if (removedKeyword) {
    bodyContent = {
      ...bodyContent, // 기존의 bodyContent 속성을 그대로 유지
      remove_keyword: 'true',
    };
  }

  try {
    const response = await fetch(`${BASE_URL}/tsb-user/user-update/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(bodyContent),
    });

    if (!response.ok) {
      throw new Error('토큰이 유효하지 않습니다.');
    }

    const body = await response.json();
    return body;
  } catch (error) {
    console.error('Error fetching user info:', error);
    return null;
  }
}

// 특허
export async function patent_keywords(type, keywordValue, id, removedKeyword) {
  const token = localStorage.getItem('token');

  let bodyContent = {};
  type === 'domestic'
    ? (bodyContent = { patents_keywords_domestic: keywordValue })
    : (bodyContent = { patents_keywords_overseas: keywordValue });

  if (removedKeyword) {
    bodyContent = {
      ...bodyContent, // 기존의 bodyContent 속성을 그대로 유지
      remove_keyword: 'true',
    };
  }

  try {
    const response = await fetch(`${BASE_URL}/tsb-user/user-update/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(bodyContent),
    });

    if (!response.ok) {
      throw new Error('토큰이 유효하지 않습니다.');
    }

    const body = await response.json();
    return body;
  } catch (error) {
    console.error('Error fetching user info:', error);
    return null;
  }
}

//stock
export async function stock_update(options = {}) {
  const { id, keywordValue, removedKeyword } = options;
  const token = localStorage.getItem('token');
  let bodyContent = {};
  bodyContent = { stock_keywords: keywordValue };

  if (removedKeyword) {
    bodyContent = {
      ...bodyContent, // 기존의 bodyContent 속성을 그대로 유지
      remove_keyword: 'true',
    };
  }

  try {
    const response = await fetch(`${BASE_URL}/tsb-user/user-update/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(bodyContent),
    });

    if (!response.ok) {
      throw new Error('토큰이 유효하지 않습니다.');
    }

    const body = await response.json();
    return body;
  } catch (error) {
    console.error('Error fetching user info:', error);
    return null;
  }
}

// 행사
export async function event_update(keywordValue, id, removedKeyword) {
  const token = localStorage.getItem('token');
  let bodyContent = {};
  bodyContent = { event_keywords: keywordValue };

  if (removedKeyword) {
    bodyContent = {
      ...bodyContent, // 기존의 bodyContent 속성을 그대로 유지
      remove_keyword: 'true',
    };
  }

  try {
    const response = await fetch(`${BASE_URL}/tsb-user/user-update/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(bodyContent),
    });

    if (!response.ok) {
      throw new Error('토큰이 유효하지 않습니다.');
    }

    const body = await response.json();
    return body;
  } catch (error) {
    console.error('Error fetching user info:', error);
    return null;
  }
}
