import { useAuth } from '../../contexts/AuthProvider';
import React, { forwardRef } from 'react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// api
import { settings_api } from '../../apis/settings_api';
import { market_update } from '../../apis/tsb_setting_api';

const MarketSetting = forwardRef((props, ref) => {
  const { user } = useAuth();
  const { market_keywords, id } = user.result;
  const sanitizedMarketKeywords = market_keywords || '';
  const [exchangeRes, setExchangeRes] = useState([]);
  const [worldExchangeRes, setWorldExchangeRes] = useState([]);
  const [oilgoldRes, setOilgoldRes] = useState([]);
  const [materialRes, setMaterialRes] = useState([]);

  // console.log(user);

  const [selectedButtons, setSelectedButtons] = useState(sanitizedMarketKeywords);
  let updatedButtons;

  useEffect(() => {
    const fetchData = async () => {
      const result = await settings_api();
      const exchangeItems = result.result.filter(item => item.category === 'exchange');
      setExchangeRes(exchangeItems);
      const worldExchangeItems = result.result.filter(item => item.category === 'worldExchange');
      setWorldExchangeRes(worldExchangeItems);
      const oilgoldItem = result.result.filter(item => item.category === 'oilgold');
      setOilgoldRes(oilgoldItem);
      const materialItem = result.result.filter(item => item.category === 'material');
      setMaterialRes(materialItem);

      const arry = sanitizedMarketKeywords.split(',');
      setSelectedButtons(arry);
    };

    fetchData();
  }, [sanitizedMarketKeywords]);

  const handleButtonClick = async (event, category) => {
    if (event.target) {
      const selectedButton = event.target.innerText;
      setSelectedButtons(prevState => {
        if (prevState.includes(selectedButton)) {
          // 버튼이 이미 선택된 상태인 경우, 해당 버튼을 배열에서 제거
          updatedButtons = prevState.filter(button => button !== selectedButton);
          marketDelete(id, selectedButton);
          toast.success('설정 정보가 저장되었습니다. 저장된 설정은 다음 날부터 적용됩니다.');
        } else {
          // 버튼이 선택되지 않은 상태인 경우, 배열에 추가
          updatedButtons = [...prevState, selectedButton];
          marketInsert(id, selectedButton);
          toast.success('설정 정보가 저장되었습니다. 저장된 설정은 다음 날부터 적용됩니다.');
        }
        // console.log('선택된버튼 :: ', updatedButtons);
        return updatedButtons;
      });
    }
  };

  const marketInsert = async (id, selectedButton) => {
    try {
      const options = {
        id: id,
        keywordValue: selectedButton,
      };
      const response = await market_update(options);
      // console.log('Update successful:', response);
      return response;
    } catch (error) {
      console.error('Update failed:', error);
      throw error;
    }
  };

  const marketDelete = async (id, selectedButton) => {
    try {
      const options = {
        id: id,
        keywordValue: selectedButton,
        removedKeyword: selectedButton,
      };
      const response = await market_update(options);
      // console.log('Delete successful:', response);
      return response;
    } catch (error) {
      console.error('Delete failed:', error);
      throw error;
    }
  };

  return (
    <div className="setting_box">
      <div className="setting_intro_title">
        <h1 className="label1 secondary">금융 지수 설정</h1>
        <p className="body2 secondary">선택한 항목에 대한 정보만 Board화면에 나타납니다.</p>
      </div>

      <div className="keyword_box">
        <p className="label2">환전고시</p>

        <div className="button-list">
          {Array.isArray(exchangeRes) && exchangeRes.length > 0 ? (
            exchangeRes.map(button => (
              <button
                className="btn_item"
                key={button.id}
                onClick={event => handleButtonClick(event, 'exchange')}
                style={{
                  backgroundColor: `${
                    selectedButtons.includes(button.item) ? '#0D1A43' : '#EFEFEF'
                  }`,
                  color: `${selectedButtons.includes(button.item) ? '#fff' : '#333'}`,
                }}
              >
                {button.item}
              </button>
            ))
          ) : (
            <p className="body2 secondary">No data available</p>
          )}
        </div>
      </div>

      <div className="keyword_box">
        <p className="label2">국제 시장 환율</p>
        <div className="button-list">
          {Array.isArray(worldExchangeRes) && worldExchangeRes.length > 0 ? (
            worldExchangeRes.map(button => (
              <button
                className="btn_item"
                key={button.id}
                onClick={event => handleButtonClick(event, 'worldExchange')}
                style={{
                  backgroundColor: `${
                    selectedButtons.includes(button.item) ? '#0D1A43' : '#EFEFEF'
                  }`,
                  color: `${selectedButtons.includes(button.item) ? '#fff' : '#333'}`,
                }}
              >
                {button.item}
              </button>
            ))
          ) : (
            <p className="body2 secondary">No data available</p>
          )}
        </div>
      </div>
      <div className="keyword_box">
        <p className="label2">유가 금 시세</p>
        <div className="button-list">
          {Array.isArray(oilgoldRes) && oilgoldRes.length > 0 ? (
            oilgoldRes.map(button => (
              <button
                className="btn_item"
                key={button.id}
                onClick={event => handleButtonClick(event, 'oilgold')}
                style={{
                  backgroundColor: `${
                    selectedButtons.includes(button.item) ? '#0D1A43' : '#EFEFEF'
                  }`,
                  color: `${selectedButtons.includes(button.item) ? '#fff' : '#333'}`,
                }}
              >
                {button.item}
              </button>
            ))
          ) : (
            <p className="body2 secondary">No data available</p>
          )}
        </div>
      </div>
      <div className="keyword_box" style={{ paddingBottom: '0px' }}>
        <p className="label2">원자재</p>
        <div className="button-list">
          {Array.isArray(materialRes) && materialRes.length > 0 ? (
            materialRes.map(button => (
              <button
                className="btn_item"
                key={button.id}
                onClick={event => handleButtonClick(event, 'material')}
                style={{
                  backgroundColor: `${
                    selectedButtons.includes(button.item) ? '#0D1A43' : '#EFEFEF'
                  }`,
                  color: `${selectedButtons.includes(button.item) ? '#fff' : '#333'}`,
                }}
              >
                {button.item}
              </button>
            ))
          ) : (
            <p className="body2 secondary">No data available</p>
          )}
        </div>
      </div>
    </div>
  );
});

export default MarketSetting;
