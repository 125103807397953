// basic
import { useState, useEffect, useCallback } from 'react';

// api
import { patent_api } from '../../../apis/patent_api.js';
import { setting_keyword } from '../../../apis/users_api.js';

// components
import RegionButtons from '../../common/RegionButtons.js';
import TagKeyword from '../../common/TagKeyword.js';
import PatentsContent from './PatentsContent.js';

import { useAuth } from '../../../contexts/AuthProvider.js';

// css
import '../../../styles/Patent.css';

// img
import infoCircle from '../../../assets/info-circle.png';

/* ───────────────────────────────────────────────────────────────
 * Patent
 * 특허 정보 화면 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

export default function Patent() {
  const { user } = useAuth();
  const { user_email } = user.result;

  const regionOption = ['국내', '해외'];
  const [selectedRegion, setSelectedRegion] = useState(regionOption[0]);
  const tagOption = ['patents_keywords_domestic', 'patents_keywords_overseas'];
  const [tagValue, setTagValue] = useState(tagOption[0]);

  const [tagKeyword, setTagKeyword] = useState({});

  const [data, setData] = useState(null);

  const handleRegionChange = region => {
    setSelectedRegion(region);

    if (region === regionOption[1]) {
      setTagValue(tagOption[1]);
    } else {
      setTagValue(tagOption[0]);
    }
  };

  // console.log(user);

  // -- 같은 조건으로 뉴스, 키워드 api 호출 -- //
  const fetchData = useCallback(async () => {
    const options = { division: selectedRegion, user_email: user_email };

    try {
      const [patentResult, keywordResult] = await Promise.all([
        patent_api(options),
        setting_keyword(options),
      ]);

      setData(patentResult);
      setTagKeyword(keywordResult);
    } catch (error) {
      // Handle errors here
      console.error('Error fetching data:', error);
    }
  }, [selectedRegion, user_email]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      {/* 국내/해외 */}
      <div className="sm_global_box d-end">
        <RegionButtons
          options={regionOption}
          selectedRegion={selectedRegion}
          onRegionChange={handleRegionChange}
        />
      </div>

      <div className="guide_box">
        <p className="body2 d-center">
          <img src={infoCircle} alt="시계이미지" />
          아이콘을 눌러 국내, 해외 기준 정보를 바꿔보세요.
        </p>
      </div>

      <div className="column">
        <div>
          {selectedRegion === regionOption[0] ? (
            <p className="title1" style={{ fontWeight: '600' }}>
              국내 특허 정보
            </p>
          ) : (
            <p className="title1" style={{ fontWeight: '600' }}>
              해외 특허 정보
            </p>
          )}
        </div>

        <div className="keyword_wrap d-flex">
          {/* 키워드 */}
          <p className="label2" style={{ paddingBottom: '5px' }}>
            키워드
          </p>
          {tagKeyword.length > 0 ? (
            <TagKeyword data={tagKeyword} tag={tagValue} />
          ) : (
            <p className="label2">No Keywords</p>
          )}
        </div>

        {/* 특허 내용 */}
        <PatentsContent data={data} selectedRegion={selectedRegion} />
      </div>

      <div className="line"></div>
    </div>
  );
}
