import { useAuth } from '../../contexts/AuthProvider';
import React, { forwardRef, useEffect, useState, useRef } from 'react';
import { DropDownContainer } from '../settingPage/StyleComponent';
import { toast } from 'react-toastify';

// api
import { event_update } from '../../apis/tsb_setting_api';
import { insert_latest_keywords_api } from '../../apis/latest_keywords_api';
import { delete_latest_keyword_api } from '../../apis/latest_keywords_api';

// common
import { formatDateToYYmm } from '../../utils/dateFunction';

// css
import 'react-toastify/dist/ReactToastify.css';

/* ───────────────────────────────────────────────────────────────
 * EventSetting
 * - 행사 키워드로 검색 후 설정 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

const EventSetting = forwardRef(({ event }, ref) => {
  // console.log(event);
  const { user } = useAuth();
  const { id, user_email } = user.result;

  const dropdownRef = useRef(); //ul에 대한 ref
  const [isDropdownView, setDropdownView] = useState(false);

  const [keyword, setKeyword] = useState('');
  const [tagKeyword, setTagKeyword] = useState({
    message: '',
    result: {
      event_keywords: '',
    },
  });

  const eventKeywordsResult = tagKeyword && tagKeyword.result;
  const event_keywords = eventKeywordsResult ? eventKeywordsResult.event_keywords || '' : '';

  function chunkArray(array = [], chunkSize) {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  }

  // const eventChunks = chunkArray(event, 5);
  const [eventChunks, setEventChunks] = useState('');
  useEffect(() => {
    setEventChunks(chunkArray(event, 10));
  }, [event]);

  //인풋 창에 텍스트를 쓸 때마다 값이 저장됨
  const handleKeywordChange = e => {
    setKeyword(e.target.value);
  };

  const handleDropDownClick = clickedOption => {
    setKeyword(`${clickedOption.keyword}`);
  };

  //   등록버튼 클릭 시
  const handleRegister = async () => {
    const regex = /^[a-zA-Z0-9ㄱ-ㅎ가-힣]*$/; //특수문자 변수
    // 입력값이 없으면 아무 동작 하지 않음
    if (keyword.trim() === '' || !regex.test(keyword)) {
      alert('특수문자를 제외한 키워드를 입력 해 주세요.');
      return;
    }
    // 기존 키워드 갯수 확인
    const currentKeywords = event_keywords.split(',').filter(keyword => keyword.trim() !== '');
    if (currentKeywords.length >= 5) {
      alert('최대 5개까지만 등록 가능합니다.');
      return;
    }
    try {
      const result = await event_update(keyword, id);
      setTagKeyword(result);
      let params = { user_email: user_email, division: 'event', keyword: keyword };
      const inserEventRes = await insert_latest_keywords_api(params);
      setEventChunks(chunkArray(inserEventRes, 10));

      // 토스트 알림
      toast.success('설정 정보가 저장되었습니다. 저장된 설정은 다음 날부터 적용됩니다.');
    } catch (error) {
      console.error('Error updating domestic keywords:', error);
    } finally {
      setKeyword('');
    }
  };

  // Enter키 입력 감지
  const handleOnKeyPress = e => {
    if (e.key === 'Enter') {
      handleRegister();
      setDropdownView(false);
    }
  };

  // 삭제 버튼 눌렀을 때
  const handleRemove = async (e, keyword) => {
    const remove = e.target.value;
    const removedKeyword = keyword;

    try {
      const result = await event_update(removedKeyword, id, remove);
      setTagKeyword(result);
    } catch (error) {
      console.error('Error updating domestic keywords:', error);
    } finally {
      setKeyword('');
    }
  };

  // 페이지가 로드될 때 키워드 보이게
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await event_update('', id);
        setTagKeyword(result);
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };
    fetchData();
  }, [id]);

  // 키워드 삭제 버튼 추가
  const renderKeywords = keywords => {
    return keywords.split(',').map((keyword, index) => {
      const handleClick = e => {
        handleRemove(e, keyword);
      };
      return (
        <span key={index} data-span-value={keyword}>
          {keyword}
          <button value="remove" onClick={e => handleClick(e, keyword)} className="remove"></button>
        </span>
      );
    });
  };

  const deleteEventKeyword = e => {
    const handleClick = async e => {
      // console.log(e.keyword, e.id);
      let params = { user_email: user_email, division: 'event', id: e.id };
      const deletereturn = await delete_latest_keyword_api(params);
      setEventChunks(chunkArray(deletereturn, 10));
    };

    // 키워드 등록일 표기용
    const date = formatDateToYYmm(new Date(e.created_at));

    return (
      <span key={e.index} data-span-value={e.keyword} className="keywords_drop_wrap">
        <div
          className="d-between"
          onClick={() => {
            handleDropDownClick(e);
          }}
        >
          <span>{e.keyword}</span>
          <span className="gray3">{date}</span>
        </div>
        <button value="remove" onClick={() => handleClick(e)} className="remove"></button>
        {/* {index < array.length - 1 && ','} */}
      </span>
    );
  };

  const handleClickContainer = () => {
    setDropdownView(!isDropdownView);
  };

  const handleBlurContainer = () => {
    setTimeout(() => {
      setDropdownView(false);
    }, 150);
  };

  return (
    <div className="setting_box">
      <div className="setting_intro_title">
        <h1 className="label1 secondary">행사 일정 설정</h1>
        <p className="body2 secondary">
          입력한 키워드(최대 5개)가 포함된 행사 일정이 Board화면에 나타납니다.
        </p>
      </div>

      <div className="keyword_box keyword_border" style={{ paddingBottom: '0px' }}>
        <p className="label2">행사 키워드(코엑스)</p>

        <div className="pass_input d-between">
          <input
            type="text"
            placeholder="행사에서 찾아봐야 할 키워드가 있나요? "
            value={keyword}
            onClick={handleClickContainer}
            onChange={handleKeywordChange}
            onKeyPress={handleOnKeyPress}
            onBlur={handleBlurContainer}
          />
          <button onClick={handleRegister} className="pass_btn">
            등록
          </button>
        </div>
        {isDropdownView && (
          <DropDown
            ref={dropdownRef}
            options={eventChunks[0]}
            handleComboBox={handleDropDownClick}
            deleteLatestKeyword={deleteEventKeyword}
          />
        )}
      </div>
      <span className="keywords body2 secondary">
        {event_keywords === '' ? '현재 등록된 키워드가 없습니다.' : renderKeywords(event_keywords)}
      </span>
    </div>
  );
});

export default EventSetting;

export const DropDown = React.forwardRef(
  ({ options, handleComboBoxm, deleteLatestKeyword }, ref) => {
    if (!options || !Array.isArray(options) || options.length === 0) {
      return null; // 옵션이 없을 경우 컴포넌트를 숨깁니다.
    }

    return (
      <DropDownContainer ref={ref}>
        {options.map((option, index) => (
          <li key={index} className="news_setting_keywords">
            <div className="keywords">
              {option.keyword === '' ? '과거키워드없음' : deleteLatestKeyword(option)}
            </div>
          </li>
        ))}
      </DropDownContainer>
    );
  },
);
