// basic
import { useState, useEffect, useCallback } from 'react';

// api
import { legislation_api, legislation_summary_api } from '../../../apis/legislation_api.js';

// components
import RegionButtons from '../../common/RegionButtons.js';
import { formatDateToYYYYmmdd } from '../../../utils/dateFunction.js';

// img
import download from '../../../assets/download.png';
import infoCircle from '../../../assets/info-circle.png';
import lockImg from '../../../assets/lock_img03.png';

/* ───────────────────────────────────────────────────────────────
 * Legislation
 * 규제 정보 화면 표시 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

export default function Legislation() {
  const regionOption = ['국내', '해외'];
  const [selectedRegion, setSelectedRegion] = useState(regionOption[0]);
  const today = formatDateToYYYYmmdd(new Date());

  const [data, setData] = useState(null);
  const [summaryData, setSummaryData] = useState(null);

  const handleRegionChange = region => {
    setSelectedRegion(region);
  };

  // -- 같은 조건으로 규제, 키워드 api 호출 -- //
  const fetchData = useCallback(async () => {
    const options = { division: selectedRegion, summary_date: today, collection_date: today };

    try {
      const [legislationResult, summaryResult] = await Promise.all([
        legislation_api(options),
        legislation_summary_api(options),
      ]);

      setData(legislationResult);
      setSummaryData(summaryResult);
    } catch (error) {
      // Handle errors here
      console.error('Error fetching data:', error);
    }
  }, [today, selectedRegion]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const regionData = data ? data.filter(item => item.division === selectedRegion) : [];

  // -- 다운로드 버튼 클릭시 링크 열기 -- //
  const detailed_page_load = item => {
    const { detail_page_url } = item;
    if (detail_page_url) {
      window.open(detail_page_url, '_blank');
    } else {
      alert('다운로드 파일이 없습니다.');
    }
  };

  return (
    <div>
      {/* 국내/해외 */}
      <div className="sm_global_box d-end">
        <RegionButtons
          options={regionOption}
          selectedRegion={selectedRegion}
          onRegionChange={handleRegionChange}
        />
      </div>

      <div className="guide_box">
        <p className="body2 d-center">
          <img src={infoCircle} alt="시계이미지" />
          아이콘을 눌러 국내, 해외 기준 정보를 바꿔보세요.
        </p>
      </div>

      <div className="column">
        {selectedRegion === regionOption[0] ? (
          <h1 className="title1" style={{ marginBottom: '0' }}>
            국내 규제 요약
          </h1>
        ) : (
          <h1 className="title1" style={{ marginBottom: '0' }}>
            해외 규제 요약
          </h1>
        )}

        <div className="NewsSummary">
          {summaryData ? (
            <div className="body1">
              <strong>{summaryData.title}</strong>
              <p
                className="scroll"
                dangerouslySetInnerHTML={{
                  __html: summaryData.content,
                }}
              />
            </div>
          ) : (
            <div className="label3 no_data_wrap">
              <img src={lockImg} alt="이미지" />
              수집된 정보가 없습니다.
              <br />
              잠시만 기다려주세요.
            </div>
          )}
        </div>

        {/* 다운로드 버튼 */}
        <div className="download_wrap">
          {regionData &&
            regionData.length > 0 &&
            regionData.map(item => (
              <div key={item.id}>
                <button onClick={() => detailed_page_load(item)} className="download">
                  <img src={download} alt="다운로드 아이콘" />
                  다운로드
                </button>
              </div>
            ))}
          {(!regionData || regionData.length === 0) && null}
        </div>
      </div>

      <div className="line"></div>
    </div>
  );
}
