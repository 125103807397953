// 개발 서버
// const BASE_URL = 'http://192.168.0.137:8080';

// // 운영 서버(AWS)
// const BASE_URL =
//   'https://rainbow-brain-backend-server-env.eba-23au4jxd.ap-northeast-2.elasticbeanstalk.com';

// 운영 서버(AWS)
const BASE_URL = 'https://server.tsb.today';

export { BASE_URL };
