//img
import { useState } from 'react';
import countryImages from '../../../utils/countryImg';
import More from '../../../assets/more_arrow.png';

/* ───────────────────────────────────────────────────────────────
 * PatentsContent
 * 특허 정보 내용 표시 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

const PatentsContent = ({ data, selectedRegion }) => {
  // 더보기 기능
  let moreBtnCount = '';
  const [moreBtnCount_domestic, setMoreBtnCount_domestic] = useState(3);
  const [moreBtnCount_overseas, setMoreBtnCount_overseas] = useState(3);

  const loadMoreData_domestic = () => {
    setMoreBtnCount_domestic(prevCount => prevCount + 5);
  };
  const loadMoreData_overseas = () => {
    setMoreBtnCount_overseas(prevCount => prevCount + 5);
  };

  if (selectedRegion === '국내') {
    moreBtnCount = moreBtnCount_domestic;
  } else {
    moreBtnCount = moreBtnCount_overseas;
  }

  // -- 특허 클릭시 상세 페이지 열기 -- //
  const detailed_page_load = item => {
    const { detail_page_url } = item;
    if (detail_page_url) {
      window.open(detail_page_url, '_blank');
    } else {
      alert('URL이 없습니다.');
    }
  };

  if (!data || data.length === 0) {
    return null; // 혹은 다른 처리 방법을 선택할 수 있습니다.
  }

  const uniqueTitleMap = data.reduce((accumulator, currentValue) => {
    accumulator[currentValue.title] = currentValue;
    return accumulator;
  }, {});

  // 중복이 제거된 객체들을 배열로 변환
  const uniqueName = Object.values(uniqueTitleMap);

  return (
    <>
      {uniqueName &&
        uniqueName.slice(0, moreBtnCount).map(item => {
          if (item.division === selectedRegion || selectedRegion === '') {
            return (
              <div className="patents_wrap" key={item.id} onClick={() => detailed_page_load(item)}>
                <ul>
                  {item.thumb_img_url == null || item.thumb_img_url === '' ? (
                    <li className="patents_img">
                      <img src="https://via.placeholder.com/150?text=No+Image" alt="샘플이미지" />
                    </li>
                  ) : (
                    <li className="patents_img">
                      <img src={item.thumb_img_url} alt="썸네일" />
                    </li>
                  )}
                </ul>
                <div className="NewsSummary">
                  <ul className="d-flex">
                    {data[0].division === '해외' ? (
                      <li>
                        <img className="patent_flag"
                          src={countryImages.find(img => img.alt === item.country)?.src}
                          alt={item.country}
                        />
                      </li>
                    ) : null}
                    <li>
                      <strong>{item.title}</strong>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <p className="body2 scroll">인공지능 요약 : {item.summary}</p>
                    </li>
                  </ul>
                </div>
              </div>
            );
          }
          return null;
        })}

      {/* 더보기 버튼 */}
      {data[0].division === '해외' ? (
        <div className="more_btn">
          <button className="body1 d-center primary" onClick={loadMoreData_overseas}>
            해외 특허 더보기 <img src={More} alt="썸네일" />
          </button>
        </div>
      ) : (
        <div className="more_btn">
          <button className="body1 d-center primary" onClick={loadMoreData_domestic}>
            국내 특허 더보기 <img src={More} alt="썸네일" />
          </button>
        </div>
      )}
    </>
  );
};
export default PatentsContent;
