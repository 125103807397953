// basic
import { useState, useEffect, useCallback } from 'react';

// api
import { invests_api, invests_keyword_api } from '../../../apis/invest_api.js';

// utils
import { formatDateToYYYYmmdd } from '../../../utils/dateFunction.js';

// components
import InvestsContent from './InvestsContent.js';
import KeywordsList from './InvestsKeyword.js';

// contexts
import { useAuth } from '../../../contexts/AuthProvider.js';

// css
import '../../../styles/Invest.css';

/* ───────────────────────────────────────────────────────────────
 * Invests : 투자 화면 표시 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

function Invests() {
  const { user } = useAuth();

  const user_invests_keyword = user.result.invests_keywords;

  const today = formatDateToYYYYmmdd(new Date()); // YYYYMMDD

  const [investsData, setInvestsData] = useState(null);
  const [investsKeyword, setInvestsKeyword] = useState(null);

  // -- 같은 조건으로 투자, 키워드 api 호출 -- //
  const fetchData = useCallback(async () => {
    const options = { collection_date: today, division: user_invests_keyword };

    try {
      const [investsResult, keywordResult] = await Promise.all([
        invests_api(options),
        invests_keyword_api(options),
      ]);

      setInvestsData(investsResult);
      setInvestsKeyword(keywordResult);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [today, user_invests_keyword]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <div className="column">
        <ul className="keyword_list">
          <li>
            <p className="title1 invest_title">
              최근 투자 기업 주요 키워드
            </p>
            <KeywordsList
              keywords={
                investsKeyword && investsKeyword.length > 0 ? investsKeyword[0].keywords : null
              }
            />
          </li>
        </ul>
      </div>

      <div className="line"></div>

      <div className="column invest_table_wrap">
        <h1 className="title1">
          <span className="primary">{user_invests_keyword}</span> 투자 정보
        </h1>

        {/* 표 */}
        <InvestsContent data={investsData} />
      </div>

      <div className="line"></div>
    </div>
  );
}

export default Invests;
