// basic
import { React, useState, useEffect } from 'react';
import Chatbot from 'react-chatbot-kit';
import config from '../../utils/chatbotConfig';
import MessageParser from '../../utils/MessageParser';
import ActionProvider from '../../utils/ActionProvider';

// css
import '../../styles/Chatbot.css';

// img
import ChatbotImg from '../../assets/chatbot.gif';

function TsbChatbot() {
  const [showBot, toggleBot] = useState(false);

  useEffect(() => {
    if (showBot) {
      document.body.classList.add('chatbot-open');
    } else {
      document.body.classList.remove('chatbot-open');
    }

    return () => {
      document.body.classList.remove('chatbot-open');
    };
  }, [showBot]);

  return (
    <div className="d-between">
      {showBot && (
        <div className="app-chatbot-container">
          <p className="title2" style={{ marginBottom: '10px' }}>
            Trend Sensing Board
          </p>
          <Chatbot
            config={config}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
            placeholderText="현재는 지원하지 않는 기능입니다."
          />
        </div>
      )}

      <button className="app-chatbot-button" onClick={() => toggleBot(prev => !prev)}>
        <img src={ChatbotImg} alt="chatbot icon" />
        {/* <div className="chatbot-button-text">문의하기</div> */}
      </button>
    </div>
  );
}

export default TsbChatbot;
