//basic
import React, { useCallback, useEffect, useState } from 'react';

//api
import { google_keywords_api } from '../../../apis/google_keywords_api.js';

//css
import '../../../styles/SocialTrend.css';

/* ───────────────────────────────────────────────────────────────
 * GoogleKeyword
 * - 구글 오늘의 인기검색어 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

export default function GoogleKeyword(props) {
  const [titles, setTitles] = useState([]); //키워드5개용
  const [isActive, setIsActive] = useState(0);

  const handleClick = index => {
    setIsActive(index);

    // const data = 'Hello from child!';
    props.onDataFromChild(index);
  };

  const fetchData = useCallback(async () => {
    const result = await google_keywords_api();

    const items = result.rss.channel[0].item;

    const titless = items ? items.slice(0, 10).flatMap(item => item.title) : [];
    // console.log('키워드 :: ', titless);
    setTitles(titless);
  }, [setTitles]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <ul>
      <li>
        {titles.map((data, index) => (
          <div key={index} className="d-flex" style={{ paddingTop: '12px' }}>
            <p className="primary title2" onClick={() => handleClick(index)}>
              {index + 1}
            </p>
            <p
              className={`body1 ${index === isActive ? 'primary' : ''}`}
              onClick={() => handleClick(index, data)}
              style={{ width: '100%' }}
            >
              {data}
            </p>
          </div>
        ))}
      </li>
    </ul>
  );
}
