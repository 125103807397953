import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

// Components
import NewsSetting from '../../components/settingPage/NewsSetting';
import MarketSetting from '../../components/settingPage/MarketSetting';
import StockSetting from '../../components/settingPage/StockSetting';
import LawSetting from '../../components/settingPage/LawSetting';
import PatentSetting from '../../components/settingPage/PatentSetting';
import InvestSetting from '../../components/settingPage/InvestSetting';
import EventSetting from '../../components/settingPage/EventSetting';

// Contexts and APIs
import { useAuth } from '../../contexts/AuthProvider';
import { latest_keywords_api } from '../../apis/latest_keywords_api';

function TSB_Setting() {
  const { user } = useAuth();
  const { user_email } = user.result;

  const [newsDomestic, setNewsDomestic] = useState('');
  const [newsOverseas, setNewsOverseas] = useState('');
  const [lawsDomestic, setLawsDomestic] = useState('');
  const [lawsOverseas, setLawsOverseas] = useState('');
  const [patentsDomestic, setPatentsDomestic] = useState('');
  const [patentsOverseas, setPatentsOverseas] = useState('');
  const [event, setEvent] = useState('');

  // Tab and screen type management
  const [activeTab, setActiveTab] = useState(0);

  // Data for tabs
  const TabData = [
    {
      idx: 0,
      button: '뉴스',
      content: <NewsSetting newsDomestic={newsDomestic} newsOverseas={newsOverseas} />,
      option: 'tsb',
    },
    { idx: 1, button: '금융', content: <MarketSetting />, option: 'tsb' },
    { idx: 2, button: '주식', content: <StockSetting />, option: 'tsb' },
    {
      idx: 3,
      button: '법규',
      content: <LawSetting lawsDomestic={lawsDomestic} lawsOverseas={lawsOverseas} />,
      option: 'tsb',
    },
    {
      idx: 4,
      button: '특허',
      content: (
        <PatentSetting patentsDomestic={patentsDomestic} patentsOverseas={patentsOverseas} />
      ),
      option: 'tsb',
    },
    { idx: 5, button: '투자', content: <InvestSetting />, option: 'tsb' },
    { idx: 6, button: '행사', content: <EventSetting event={event} />, option: 'tsb' },
  ];

  // Effect to fetch initial data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const latest_setting_keyword = await latest_keywords_api({ user_email });
        const filterByDivision = division =>
          latest_setting_keyword.filter(keyword => keyword.division === division);

        setNewsDomestic(filterByDivision('news_domestic'));
        setNewsOverseas(filterByDivision('news_overseas'));
        setLawsDomestic(filterByDivision('laws_domestic'));
        setLawsOverseas(filterByDivision('laws_overseas'));
        setPatentsDomestic(filterByDivision('patents_domestic'));
        setPatentsOverseas(filterByDivision('patents_overseas'));
        setEvent(filterByDivision('event'));
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };

    fetchData();
  }, [user_email]);

  return (
    <div className="tsb_setting_wrap">
      <p className="title1">TSB 설정</p>

      <div className="tsb_tab_container">
        {TabData.map(tab => (
          <div
            key={tab.idx}
            className={activeTab === tab.idx ? 'active' : ''}
            onClick={() => setActiveTab(tab.idx)}
          >
            {tab.button}
          </div>
        ))}
      </div>

      <div className="setting_container">
        {TabData.map(
          tab =>
            activeTab === tab.idx && (
              <div key={tab.idx} className={activeTab === tab.idx ? 'active' : ''}>
                {tab.content}
              </div>
            ),
        )}
      </div>

      <ToastContainer autoClose={1000} limit={1} HideProgressBar />
    </div>
  );
}

export default TSB_Setting;
