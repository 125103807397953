// basic
import { useState, useEffect, useCallback } from 'react';

// common
import { formatDateToYYYYmmdd } from '../../../utils/dateFunction.js';
import RegionButtons from '../../common/RegionButtons.js';

// api
import { reports_api } from '../../../apis/reports_api.js';

// img
import infoCircle from '../../../assets/info-circle.png';
import move from '../../../assets/move.png';
import lockImg from '../../../assets/lock_img03.png';

/* ───────────────────────────────────────────────────────────────
 * Reports
 * 기업리포트  화면 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

function Reports() {
  const today = formatDateToYYYYmmdd(new Date()); // YYYYMMDD

  const [reportsData, setReportsData] = useState(null);
  // console.log(reportsData);

  //   해외 국내 분류
  const regionOption = ['국내', '해외'];
  const [selectedRegion, setSelectedRegion] = useState(regionOption[0]);
  const handleRegionChange = region => {
    setSelectedRegion(region);
  };

  // -- 리포트 클릭시 상세 페이지 열기 -- //
  const detailed_page_load = item => {
    const { detail_page_url } = item;
    if (detail_page_url) {
      window.open(detail_page_url, '_blank');
    } else {
      alert('기업 리포트 URL이 없습니다.');
    }
  };

  // 해외 일때는 한달기준 최신순 10개만 보이고 국내 일때는 10일전
  const year = parseInt(today.substring(0, 4));
  const month = parseInt(today.substring(4, 6)) - 1; // JavaScript의 월은 0부터 시작하므로 1을 빼줍니다.
  const day = parseInt(today.substring(6, 8));
  const today2 = new Date(year, month, day); // 문자열을 Date 객체로 변환
  today2.setDate(today2.getDate() - 30); // 현재 날짜에서 30일을 빼어 업데이트

  const fetchData = useCallback(async () => {
    const options = { post_date: today, division: selectedRegion };

    try {
      const [reportsResult] = await Promise.all([reports_api(options)]);
      setReportsData(reportsResult);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [selectedRegion, today]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (!reportsData || reportsData.length === 0) {
    // console.log('데이터가 없습니다.');
    return null; // 혹은 다른 처리 방법을 선택할 수 있습니다.
  }

  // name 값을 기준으로 객체를 그룹화하여 중복 제거
  const uniqueNameMap = reportsData.reduce((accumulator, currentValue) => {
    accumulator[currentValue.name] = currentValue;
    return accumulator;
  }, {});

  // 중복이 제거된 객체들을 배열로 변환
  const uniqueName = Object.values(uniqueNameMap);

  return (
    <div>
      {/* 국내/해외 */}
      <div className="sm_global_box d-end">
        <RegionButtons
          options={regionOption}
          selectedRegion={selectedRegion}
          onRegionChange={handleRegionChange}
        />
      </div>

      <div className="guide_box">
        <p className="body2 d-center">
          <img src={infoCircle} alt="시계이미지" />
          아이콘을 눌러 국내, 해외 기준 정보를 바꿔보세요.
        </p>
      </div>

      <div className="report_wrap">
        <table className="invests_table report_table">
          <thead className="body2">
            <tr>
              <th style={{ paddingLeft: '20px' }}>종목명</th>
              <th>출처</th>
              <th>상세</th>
            </tr>
          </thead>

          {uniqueName && uniqueName.length > 0 ? (
            <tbody>
              {uniqueName.map(item => (
                <tr key={item.id}>
                  <td style={{ paddingLeft: '20px' }}>{item.name}</td>
                  <td>{item.source}</td>
                  <td style={{ paddingRight: '20px' }} onClick={() => detailed_page_load(item)}>
                    <img src={move} alt="이동 아이콘 이미지" style={{ cursor: 'pointer' }} />
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <div className="label3 no_data_wrap">
              <img src={lockImg} alt="이미지" />
              수집된 정보가 없습니다.
              <br />
              잠시만 기다려주세요.
            </div>
          )}
        </table>
      </div>

      <div className="line"></div>
    </div>
  );
}

export default Reports;
