import React from 'react';
import { Helmet } from 'react-helmet';
import LoginForm from '../../components/loginPage/LoginForm';

//img
import naver from '../../assets/naver_icon.png';
import news from '../../assets/news_icon.png';
import youtube from '../../assets/youtube_icon.png';

function LoginPage() {
  // console.log('로그인 페이지');
  return (
    <>
      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>Trend Sensing Board</title>
      </Helmet>

      <div className="login_wrap">
        <div className="login_container">
          <p className="title1">Trend Sensing Board</p>
          <LoginForm />

          <div className="link">
            <a
              href="https://blog.naver.com/rainbow-brain"
              className="d-start"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="d-flex">
                <img src={naver} alt="로고 이미지" />
                <p className="body2">네이버 블로그 방문</p>
              </div>
            </a>
            <a
              href="https://page.stibee.com/subscriptions/146545"
              className="d-start"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="d-flex">
                <img src={news} alt="로고 이미지" />
                <p className="body2">뉴스레터 구독</p>
              </div>
            </a>
            <a
              href="https://www.youtube.com/channel/UCoVz-GdDv4hkz6el7k7ogfg"
              className="d-start"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="d-flex">
                <img src={youtube} alt="로고 이미지" />
                <p className="body2">YouTube 채널 방문</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
