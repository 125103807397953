// CountryImage.js
import 미국 from '../assets/America.png';
import 유럽 from '../assets/Europe.png';
import PCT from '../assets/PCT.png';
import 일본 from '../assets/Japan.png';
import 중국 from '../assets/China.png';
import 영국 from '../assets/England.png';
import 호주 from '../assets/Australia.png';
import 프랑스 from '../assets/France.png';
import 대만 from '../assets/Taiwan.png';
import 러시아 from '../assets/Russia.png';
import 한국 from '../assets/korea.png';
import 인도네시아 from '../assets/Indonesia.png';
import 아랍에미리트 from '../assets/Arab.png';
import 페루 from '../assets/Peru.png';
import 우크라이나 from '../assets/Ukraine.png';
import 카자흐스탄 from '../assets/Kazakhstan.png';
import 태국 from '../assets/Thailand.png';
import 브루나이 from '../assets/Brunei.png';
import 중국홍콩특별행정구 from '../assets/Hongkong.png';
import 에콰도르 from '../assets/Ecuador.png';
import 벨라루스 from '../assets/Belarus.png';
import 필리핀 from '../assets/Philippines.png';
import 캄보디아 from '../assets/Cambodia.png';
import 말레이시아 from '../assets/Malaysia.png';
import 베트남 from '../assets/Vietnam.png';
import 독일 from '../assets/Germany.png';
import 키르기스스탄 from '../assets/Kyrgyzstan.png';
import 튀르키예 from '../assets/Turkiye.png';
import 유럽연합 from '../assets/EuropeanUnion.png';
import 포르투갈 from '../assets/Portugal.png';
import 브라질 from '../assets/Brazil.png';
import 이라크 from '../assets/Iraq.png';
import 오만 from '../assets/Oman.png';
import 오스트리아 from '../assets/Austria.png';
import 멕시코 from '../assets/Mexico.png';
import 스페인 from '../assets/Spain.png';
import 우즈베키스탄 from '../assets/Uzbekistan.png';
import 투르크메니스탄 from '../assets/Turkmenistan.png';
import 캐나다 from '../assets/Canada.png';
import 중국마카오특별행정구 from '../assets/macao.png';
import 국제연합 from '../assets/un.jpg';
import 칠레 from '../assets/Chile.png';

const countryImages = [
  { id: 1, src: 미국, alt: '미국' },
  { id: 2, src: 유럽, alt: '유럽' },
  { id: 3, src: PCT, alt: 'PCT' },
  { id: 4, src: 일본, alt: '일본' },
  { id: 5, src: 중국, alt: '중국' },
  { id: 6, src: 영국, alt: '영국' },
  { id: 7, src: 호주, alt: '호주' },
  { id: 8, src: 프랑스, alt: '프랑스' },
  { id: 9, src: 대만, alt: '대만' },
  { id: 10, src: 러시아, alt: '러시아' },
  { id: 11, src: 한국, alt: '한국' },
  { id: 12, src: 인도네시아, alt: '인도네시아' },
  { id: 13, src: 아랍에미리트, alt: '아랍에미리트' },
  { id: 14, src: 페루, alt: '페루' },
  { id: 15, src: 우크라이나, alt: '우크라이나' },
  { id: 16, src: 태국, alt: '태국' },
  { id: 17, src: 카자흐스탄, alt: '카자흐스탄' },
  { id: 18, src: 브루나이, alt: '브루나이' },
  { id: 19, src: 중국홍콩특별행정구, alt: '중국홍콩특별행정구' },
  { id: 20, src: 에콰도르, alt: '에콰도르' },
  { id: 21, src: 벨라루스, alt: '벨라루스' },
  { id: 22, src: 필리핀, alt: '필리핀' },
  { id: 23, src: 캄보디아, alt: '캄보디아' },
  { id: 24, src: 말레이시아, alt: '말레이시아' },
  { id: 25, src: 베트남, alt: '베트남' },
  { id: 26, src: 독일, alt: '독일' },
  { id: 27, src: 키르기스스탄, alt: '키르기스스탄' },
  { id: 28, src: 튀르키예, alt: '튀르키예' },
  { id: 29, src: 유럽연합, alt: '유럽연합' },
  { id: 30, src: 포르투갈, alt: '포르투갈' },
  { id: 31, src: 브라질, alt: '브라질' },
  { id: 32, src: 이라크, alt: '이라크' },
  { id: 33, src: 오만, alt: '오만' },
  { id: 34, src: 오스트리아, alt: '오스트리아' },
  { id: 35, src: 멕시코, alt: '멕시코' },
  { id: 36, src: 스페인, alt: '스페인' },
  { id: 37, src: 우즈베키스탄, alt: '우즈베키스탄' },
  { id: 38, src: 투르크메니스탄, alt: '투르크메니스탄' },
  { id: 39, src: 캐나다, alt: '캐나다' },
  { id: 40, src: 중국마카오특별행정구, alt: '중국마카오특별행정구' },
  { id: 41, src: 국제연합, alt: '국제연합' },
  { id: 42, src: 칠레, alt: '칠레' },
];

export default countryImages;
