import React, { useState, forwardRef } from 'react';
import { useAuth } from '../../contexts/AuthProvider';

import { updateUserInfo } from '../../apis/users_api';

//css
import '../../styles/Setting.css';

const CompanySetting = forwardRef((props, ref) => {
  const { user } = useAuth();
  const { id, user_company, collection_company, collection_company_info } = user.result;

  // 회사명(GPT질문첨부용)
  const [collectionCompany, setCollectionCompany] = useState(collection_company || user_company);

  // 회사 소개(GPT질문첨부용)
  const [collectionCompanyInfo, setCollectionCompanyInfo] = useState(collection_company_info);

  //─────────────────── 회사명(GPT질문첨부용) ───────────────────//

  const handleCollectionCompanyChange = event => {
    const CollectionCompany = event.target.value;
    setCollectionCompany(CollectionCompany);
  };

  //─────────────────── 회사 소개(GPT질문첨부용) ───────────────────//

  const handleCollectionCompanyInfoChange = event => {
    const CollectionCompanyInfo = event.target.value;
    setCollectionCompanyInfo(CollectionCompanyInfo);
  };

  // console.log(department);

  //──────────────────────────────────────//

  const handleEditBtnClick = async () => {
    const options = {
      collection_company: collectionCompany,
      collection_company_info: collectionCompanyInfo,
    };

    // 유저 정보 업데이트 성공여부에 따라 알림창 다르게 표시.
    try {
      await updateUserInfo(id, options);
      alert('입력하신 회사정보가 변경되었습니다.');
    } catch (error) {
      console.error('사용자 정보 업데이트 오류:', error);
      alert('회사 정보 업데이트 중 오류가 발생했습니다.');
    }
  };

  return (
    <div>
      <div className="setting_intro_title">
        <h1 className="label1 secondary">회사 정보</h1>
        <p className="body2 secondary">
          TSB의 맞춤 정보를 확인하기 위한 회사 정보를 입력하세요.
          <br />
          회사 정보가 없는 경우, 소속 회사 기준 정보를 자동으로 가져옵니다.
        </p>
      </div>

      <div className="keyword_box">
        <p className="label2">회사명</p>
        <input
          id="collection_company"
          value={collectionCompany}
          onChange={handleCollectionCompanyChange}
        />
      </div>

      <div className="keyword_box">
        <p className="label2">회사 소개</p>
        <textarea
          id="collection_company_info"
          value={collectionCompanyInfo}
          onChange={handleCollectionCompanyInfoChange}
        />
      </div>

      <div className="setting_btn">
        <button onClick={handleEditBtnClick} className="label1">
          설정 저장
        </button>
      </div>
    </div>
  );
});

export default CompanySetting;
