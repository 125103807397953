import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthProvider';
import profile from '../../assets/person.png';

function SignInOutButton() {
  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/';
  };

  const { user } = useAuth();
  const { user_thumb_img } = user.result;

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const closeDropdown = () => {
    setDropdownVisible(false);
  };

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="dropdown">
        {user_thumb_img ? (
          <img src={user_thumb_img} alt="UserThumbnail" id="person" onClick={toggleDropdown} />
        ) : (
          <img src={profile} alt="Person" id="person" onClick={toggleDropdown} />
        )}
        {dropdownVisible && (
          <div className={`dropdown-content ${dropdownVisible ? 'visible' : ''}`}>
            {/* NavLink 클릭 시 다운드랍박스를 닫도록 closeDropdown 함수를 전달 */}

            <NavLink
              to="/setting"
              className="navlink body1"
              onClick={() => {
                closeDropdown();
                handleClick();
              }}
            >
              설정
            </NavLink>

            <a href="#!" className="body1" onClick={handleLogout}>
              로그아웃
            </a>
          </div>
        )}
      </div>
    </>
  );
}

export default SignInOutButton;
