// basic
import { useState, useEffect, useCallback } from 'react';

// api
import { news_api, news_summary } from '../../../apis/news_api.js';
import { setting_keyword } from '../../../apis/users_api.js';

// common
import { formatDateToYYYYmmdd } from '../../../utils/dateFunction.js';

// contexts
import { useAuth } from '../../../contexts/AuthProvider.js';

// components
import FieldKeywords from './news_keyword/FieldKeywords.js';
import NewsSummary from './news/NewsSummary.js';
import NewsList from './news/NewsList.js';
import TagKeyword from '../../common/TagKeyword.js';

// external library
import RegionButtons from '../../common/RegionButtons.js';

// css
import '../../../styles/Industry.css';

// img
import infoCircle from '../../../assets/info-circle.png';

/* ───────────────────────────────────────────────────────────────
 * IndustryTrends : 뉴스, 주요 키워드 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

function IndustryTrends() {
  const { user } = useAuth();

  const today = formatDateToYYYYmmdd(new Date());

  const regionOption = ['국내', '해외'];
  const [selectedRegion, setSelectedRegion] = useState(regionOption[0]);
  const [newsData, setNewsData] = useState(null);

  const tagOption = ['news_keywords_domestic', 'news_keywords_overseas'];
  const [tagValue, setTagValue] = useState(tagOption[0]);
  const [tagKeyword, setTagKeyword] = useState({});

  const [summaryNewsData, setSummaryNewsData] = useState(null);

  const user_email = user.result.user_email || '';

  const domesticKeywords = user?.result?.news_keywords_domestic || '';
  const overseasKeywords = user?.result?.news_keywords_overseas || '';
  let newsKeywords = '';

  if (domesticKeywords && overseasKeywords) {
    newsKeywords = `${domesticKeywords},${overseasKeywords}`;
  } else if (domesticKeywords) {
    newsKeywords = domesticKeywords;
  } else if (overseasKeywords) {
    newsKeywords = overseasKeywords;
  }

  const handleRegionChange = region => {
    setSelectedRegion(region);

    if (region === regionOption[1]) {
      setTagValue(tagOption[1]);
    } else {
      setTagValue(tagOption[0]);
    }
    // fetchData();
  };

  // -- 같은 조건으로 뉴스, 키워드 api 호출 -- //

  const fetchData = useCallback(async () => {
    const options = {
      division: selectedRegion,
      keyword: newsKeywords,
      post_date: today,
      user_email: user_email,
      func: '요약',
      header: 'True',
    };

    try {
      const [newsResult, keywordResult, summaryResult] = await Promise.all([
        news_api(options),
        setting_keyword(options),
        news_summary(options),
      ]);

      setNewsData(newsResult);
      setTagKeyword(keywordResult);
      setSummaryNewsData(summaryResult);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [selectedRegion, newsKeywords, today, user_email]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      {/* 국내/해외 */}
      <div className="sm_global_box d-end">
        <RegionButtons
          options={regionOption}
          selectedRegion={selectedRegion}
          onRegionChange={handleRegionChange}
        />
      </div>

      <div className="guide_box">
        <p className="body2 d-center">
          <img src={infoCircle} alt="시계이미지" />
          아이콘을 눌러 국내, 해외 기준 정보를 바꿔보세요.
        </p>
      </div>

      <div className="column">
        <h1 className="title1">뉴스 요약</h1>
        <div className="keyword_wrap d-flex">
          {/* 키워드 */}
          <p className="label2" style={{ paddingBottom: '5px' }}>
            키워드
          </p>
          {tagKeyword.length > 0 ? (
            <TagKeyword data={tagKeyword} tag={tagValue} />
          ) : (
            <p>No Keywords</p>
          )}
        </div>
        <div className="scroll">
          <NewsSummary
            data={summaryNewsData}
            selectedRegion={selectedRegion}
            onRegionChange={handleRegionChange}
          />
        </div>
      </div>

      <div className="line"></div>

      <div className="column">
        <h1 className="title1">뉴스 키워드</h1>
        <FieldKeywords selectedRegion={selectedRegion} />
      </div>

      <div className="line"></div>

      <div className="column">
        <h1 className="title1">뉴스 리스트</h1>
        <div className="scroll" style={{ marginTop: '10px' }}>
          <NewsList
            data={newsData}
            selectedRegion={selectedRegion}
            onRegionChange={handleRegionChange}
          />
        </div>
      </div>

      <div className="line"></div>
    </div>
  );
}

export default IndustryTrends;
