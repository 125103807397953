import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { AuthProvider } from './contexts/AuthProvider.js';

import App from './components/app/App.js';

import HomePage from './pages/homePage/HomePage.js';
import SettingPage from './pages/settingPage/SettingPage.js';

import IndustryTrends from './components/homePage/news&keyword/IndustryTrends.js';
import MarketStockStatus from './components/homePage/market&stock/MarketStockStatus.js';
import Patent from './components/homePage/patent/Patent.js';
import Legislation from './components/homePage/legislation/Legislation.js';
import Law from './components/homePage/law/Laws.js';
import Invest from './components/homePage/invests/Invest.js';
import Report from './components/homePage/report/Report.js';
import Techmeme from './components/homePage/techmeme/techmeme.js';
import SocialTrend from './components/homePage/socialTrend/SocialTrend.js';
import EventSchedule from './components/homePage/eventSchedule/EventSchedule.js';

/*
 *  ─────────── [↓ APP ] ─────────────────────────────────────────────────
 */

function Main() {
  return (
    <>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<App />}>
              <Route index element={<HomePage />} />
              <Route path="Industry" element={<IndustryTrends />} />
              <Route path="MarketStockStatus" element={<MarketStockStatus />} />
              <Route path="Patent" element={<Patent />} />
              <Route path="Legislation" element={<Legislation />} />
              <Route path="Law" element={<Law />} />
              <Route path="Invest" element={<Invest />} />
              <Route path="Report" element={<Report />} />
              <Route path="SocialTrend" element={<SocialTrend />} />
              <Route path="EventSchedule" element={<EventSchedule />} />
              <Route path="Techmeme" element={<Techmeme />} />
              <Route path="setting" element={<SettingPage />} />
            </Route>
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </>
  );
}

export default Main;
