// basic
import React, { useState, useEffect } from 'react';

// common
import { formatDateToYYYYmmdd } from '../../../../utils/dateFunction.js';

// img
import lockImg from '../../../../assets/lock_img04.png';

/* ───────────────────────────────────────────────────────────────
 * StockSummary
 * 주식 요약 내용 표시 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

const StockSummary = ({ data }) => {
  const today = formatDateToYYYYmmdd(new Date()); // YYYYMMDD
  const [showMessage, setShowMessage] = useState(true);

  const topMostItem = data && data.length > 0 ? [data[0]] : [];

  useEffect(() => {
    if (data && data.length > 0) {
      const hasSummaryData = data.some(item => item.summary_date === today);
      setShowMessage(!hasSummaryData);
    } else {
      setShowMessage(true);
    }
  }, [data, today]);

  // 요약 수집기준 시간 포멧
  const formatCreatedAt = createdAt => {
    const date = new Date(createdAt);
    const formattedDate = `${date.getFullYear()}.${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}.${date.getDate().toString().padStart(2, '0')} ${date
      .getHours()
      .toString()
      .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date
      .getSeconds()
      .toString()
      .padStart(2, '0')}`;

    return formattedDate;
  };

  // const filteredStock = data ? data.filter(item => item.summary_date === today) : [];
  return (
    <div className="MarketSummary">
      {topMostItem ? (
        <div>
          {topMostItem.map(item => (
            <div key={item.id}>
              <p className="body2 gray3" style={{ marginTop: '30px' }}>
                주식 현황 요약 {formatCreatedAt(data[0].created_at)} 기준
              </p>
              <div>
                <p
                  className="body1"
                  dangerouslySetInnerHTML={{
                    __html: item.content,
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        showMessage && (
          <div className="label3 no_data_wrap">
            <img src={lockImg} alt="이미지" />
            수집된 정보가 없습니다.
            <br />
            법규 키워드를 설정해주세요.
            <br />
            <span className="label4 primary">
              해당 설정로 수집된 정보가 없다면
              <br />
              다음 날 최신 정보를 수집하여 보여 드립니다.
            </span>
          </div>
        )
      )}
    </div>
  );
};

export default StockSummary;
