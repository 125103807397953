import React, { forwardRef } from 'react';
import { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthProvider';
import { toast } from 'react-toastify';

// api
import { settings_api } from '../../apis/settings_api';
import { invest_update } from '../../apis/tsb_setting_api';

// css
import 'react-toastify/dist/ReactToastify.css';

const InvestSetting = forwardRef((props, ref) => {
  const { user } = useAuth();
  const { invests_keywords, id } = user.result;
  const sanitizedInvestKeywords = invests_keywords || '';
  const [investRes, setInvestRes] = useState([]);
  const [selectedButtons, setSelectedButtons] = useState(sanitizedInvestKeywords);

  const investInsert = async (id, selectedButton) => {
    try {
      const options = {
        id: id,
        keywordValue: selectedButton,
      };
      const response = await invest_update(options);
      console.log('Update successful:', response);
      return response;
    } catch (error) {
      console.error('Update failed:', error);
      throw error;
    }
  };

  const investDelete = async (id, selectedButton) => {
    try {
      const options = {
        id: id,
        keywordValue: selectedButton,
        removedKeyword: selectedButton,
      };
      const response = await invest_update(options);
      console.log('Delete successful:', response);
      return response;
    } catch (error) {
      console.error('Delete failed:', error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const options = {
        division: 'invests',
      };
      const result = await settings_api(options);
      setInvestRes(result.result);

      const arry = sanitizedInvestKeywords.split(',');
      setSelectedButtons(arry);
    };
    fetchData();
  }, [sanitizedInvestKeywords]);

  const handleButtonClick = async (event, category) => {
    if (event.target) {
      const selectedButton = event.target.innerText;
      if (selectedButtons.includes(selectedButton)) {
        // 버튼이 이미 선택된 상태인 경우, 해당 버튼을 배열에서 제거
        await investDelete(id, selectedButton);
        setSelectedButtons(prevState => prevState.filter(button => button !== selectedButton));
      } else {
        // 선택된 값이 없는 경우, 새로운 버튼 추가
        if (selectedButtons.length === 0) {
          await investInsert(id, selectedButton);
          setSelectedButtons([selectedButton]);
          toast.success('설정 정보가 저장되었습니다. 저장된 설정은 다음 날부터 적용됩니다.');
        } else {
          // 기존의 모든 버튼을 삭제하고 새로운 버튼 추가
          for (const removedButton of selectedButtons) {
            await investDelete(id, removedButton);
          }
          await investInsert(id, selectedButton);
          setSelectedButtons([selectedButton]);
          toast.success('설정 정보가 저장되었습니다. 저장된 설정은 다음 날부터 적용됩니다.');
        }
      }
    }
  };

  return (
    <div className="setting_box">
      <div className="setting_intro_title">
        <h1 className="label1 secondary">투자 정보 설정</h1>
        <p className="body2 secondary">선택한 항목에 대한 정보만 Board화면에 나타납니다.</p>
      </div>

      <div className="keyword_box">
        <p className="label2">투자카테고리</p>

        <div className="button-list">
          {Array.isArray(investRes) && investRes.length > 0 ? (
            investRes.map(button => (
              <button
                className="btn_item"
                key={button.id}
                onClick={event => handleButtonClick(event, 'invests')}
                style={{
                  backgroundColor: `${
                    selectedButtons.includes(button.item) ? '#0D1A43' : '#EFEFEF'
                  }`,
                  color: `${selectedButtons.includes(button.item) ? '#fff' : '#333'}`,
                }}
              >
                {button.item}
              </button>
            ))
          ) : (
            <p className="body2 secondary">No data available</p>
          )}
        </div>
      </div>
    </div>
  );
});
export default InvestSetting;
