// basic
import { useState, useEffect, useCallback } from 'react';

// api
import { event_schedule_api } from '../../../apis/event_schedule_api.js';

// img
import lockImg from '../../../assets/lock_img03.png';
import noImage from '../../../assets/no-image.png';

// css
import '../../../styles/Event.css';

/* ───────────────────────────────────────────────────────────────
 * EventSchedule
 * 행사일정
 * ───────────────────────────────────────────────────────────────
 */

function EventSchedule() {
  const todayDate = new Date(); // 현재 날짜를 가져옵니다.

  // today 변수에 현재 날짜를 "YYYY-MM-DD" 형식으로 담습니다.
  const today = `${todayDate.getFullYear()}/${(todayDate.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${todayDate.getDate().toString().padStart(2, '0')}`;

  const [eventData, setEventData] = useState(null);

  // -- 클릭시 상세 페이지 열기 -- //
  const detailed_page_load = item => {
    const { detail_page_url } = item;
    if (detail_page_url) {
      window.open(detail_page_url, '_blank');
    } else {
      alert('행사일정 URL이 없습니다.');
    }
  };

  const fetchData = useCallback(async () => {
    const options = { division: '국내' };

    try {
      const [eventResult] = await Promise.all([event_schedule_api(options)]);
      setEventData(eventResult);
    } catch (error) {
      // Handle errors here
      console.error('Error Event Schedule : ', error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function calculateDateDifference(date1, date2) {
    // "yyyy-mm-dd" 형식의 문자열을 Date 객체로 변환
    const date1Obj = new Date(date1.replace(/-/g, '/'));
    const date2Obj = new Date(date2.replace(/-/g, '/'));

    // 두 날짜 간의 차이를 밀리초 단위로 계산
    // const differenceInDays = Math.ceil((date1Obj - date2Obj) / (1000 * 60 * 60 * 24));
    const timegap = date1Obj.getTime() - date2Obj.getTime();
    const differenceInDays = Math.floor(timegap / (1000 * 60 * 60 * 24));

    // 차이가 양수면 현재 날짜 이후, 음수면 현재 날짜 이전
    const prefix = differenceInDays >= 0 ? 'D-' : 'D+';
    return `${prefix}${Math.abs(differenceInDays).toString().padStart(2, '0')}`;
  }

  return (
    <div>
      <div className="column">
        <div className="scroll">
          {eventData && eventData.length > 0 ? (
            <div className="event_wrap">
              {eventData.map(item => (
                <div key={item.id} className="d-flex">
                  <ul>
                    <li>
                      <img
                        className="event_img"
                        src={item.thumb_img_url}
                        onError={e => {
                          e.target.src = noImage;
                        }}
                        alt="이벤트 이미지"
                      />
                    </li>
                  </ul>
                  <ul onClick={() => detailed_page_load(item)} className="event_ul">
                    <li className="d-between">
                      <p className="body3 same">
                        {item.event_schedule.length > 10
                          ? `${item.event_schedule.slice(0, 10)}`
                          : item.event_schedule}
                        &nbsp;&nbsp;{item.event_time}
                      </p>
                      <p className="body3 primaryD ">
                        {`${calculateDateDifference(
                          item.event_schedule.length > 10
                            ? item.event_schedule.slice(0, 10)
                            : item.event_schedule,
                          today,
                        )}`}
                      </p>
                    </li>

                    <li className="title3">{item.event_name}</li>

                    <li className="body3 primaryD ">{item.event_location}</li>
                  </ul>
                </div>
              ))}
            </div>
          ) : (
            <div className="label3 no_data_wrap">
              <img src={lockImg} alt="이미지" />
              수집된 정보가 없습니다.
              <br />
              잠시만 기다려주세요.
            </div>
          )}
        </div>
      </div>

      <div className="line"></div>
    </div>
  );
}

export default EventSchedule;
