// basic
import { useState, useEffect, useCallback } from 'react';

// api
import { market_api, market_summary_api } from '../../../apis/market_api.js';
import { stock_api, stock_summary_api } from '../../../apis/stock_api.js';

// components
import MarketList from './market/MarketList.js';
import StockList from './stock/StockList.js';
import StockSummary from './stock/StockSummary.js';
import MarketSummary from './market/MarketSummary.js';

// contexts
import { useAuth } from '../../../contexts/AuthProvider.js';

// external library
import { formatDateToYYYYmmdd } from '../../../utils/dateFunction.js';

// css
import '../../../styles/MarketStock.css';

/* ───────────────────────────────────────────────────────────────
 * MarketStockStatus
 * 주식 & 금융 요약 내용, 리스트 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

function MarketStockStatus() {
  const { user } = useAuth();
  const user_email = user.result.user_email;

  const today = formatDateToYYYYmmdd(new Date()); // YYYYMMDD

  const [marketData, setMarketData] = useState(null);
  const [marketSumData, setMarketSumData] = useState(null);
  const [stockData, setStockData] = useState(null);
  const [stockSumData, setStockSumData] = useState(null);

  //   // -- 같은 조건으로 금융 주식 api 호출 -- //
  const fetchData = useCallback(async () => {
    const options = {
      market_collection_date: today,
      stock_collection_date: today,
      user_email: user_email,
    };

    try {
      const [marketResult, stockResult, marketSumResult, stockSumResult] = await Promise.all([
        market_api(options),
        stock_api(options),
        market_summary_api(options),
        stock_summary_api(options),
      ]);

      setMarketData(marketResult);
      setStockData(stockResult);
      setMarketSumData(marketSumResult);
      setStockSumData(stockSumResult);
    } catch (error) {
      // Handle errors here
      console.error('Error fetching data:', error);
    }
  }, [today, user_email]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <div className="top">
        <div className="column">
          <h1 className="title1">현황 요약</h1>
          <div className="scroll">
            <MarketSummary data={marketSumData} />
            <StockSummary data={stockSumData} />
          </div>
        </div>

        <div className="line"></div>

        <div className="maket_box column">
          <h1 className="title1">금융 환율</h1>
          <MarketList data={marketData} />
        </div>

        <div className="line"></div>

        <div className="maket_box column">
          <h1 className="title1">주식</h1>
          <StockList data={stockData} />
        </div>
      </div>

      <div className="line"></div>
    </div>
  );
}

export default MarketStockStatus;
