//basic
import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

//api
// import { naver_keywords_api } from '../../../apis/naver_keywords_api.js';
import { google_trend_api } from '../../../apis/google_trend_api.js';

//img
import '../../../styles/Reset.css';
import '../../../styles/Common.css';
import '../../../styles/SocialTrend.css';
import spiner from '../../../assets/spiner.gif';
import badRequestImg from '../../../assets/badRequest.png';

/* ───────────────────────────────────────────────────────────────
 * 구글연관검색어 homepage
 * - 구글연관검색어 네트워크그래프 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */
if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      // if (console) {
      //   console.warn('Cannot remove a child from a different parent');
      // }
      return child;
    }
    return originalRemoveChild.apply(this, arguments);
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      // if (console) {
      //   console.warn('Cannot insert before a reference node from a different parent');
      // }
      return newNode;
    }
    return originalInsertBefore.apply(this, arguments);
  };
}

export default function GoogleRelatedKeywrod(parameter) {
  const svgRef = useRef();

  const [loging, setLoding] = useState(false);
  const [badRequest, setBadRequest] = useState(false);

  // console.log(badRequest);
  // const [innerstate, setInnerstate] = useState(false);
  // const svgElement = svgRef.current;
  // console.log('실제 돔:', svgElement);
  useEffect(() => {
    const colors = ['#2C959C', '#1EB3B8', '#5CCBA3', '#96E587'];

    const fetchData = async () => {
      try {
        d3.select(svgRef.current).selectAll('*').remove();
        const dataFromParam = parameter?.data?.param?.item;
        const changeIndex = parameter?.data?.changeIndex;

        setLoding(false);
        setBadRequest(false);

        if (dataFromParam) {
          const keywordOne = dataFromParam[changeIndex]?.title;
          let keywordApi;
          if (keywordOne) {
            const options = { hintKeyword: keywordOne };
            keywordApi = await google_trend_api(options);

            if (!keywordApi || keywordApi.length === 0) {
              setBadRequest(true);
              return;
            }

            setLoding(true);
            // setInnerstate(true);
          }
          // let resultRelatedKeywordsNine;
          // resultRelatedKeywordsNine = keywordApi.rankedKeyword.slice(0, 15);
          // if (innerstate) {
          //   console.log('dkxd');
          //   d3.select(svgRef.current).selectAll('*').remove();
          // }
          // console.log(keywordApi);
          const nodes = [
            { id: keywordOne[0] },
            ...keywordApi.map(keywordApi => ({ id: keywordApi })),
          ];

          // console.log('node', nodes);

          const links = nodes.map(keyword => {
            const keywordLength = keyword.id ? keyword.id.length : 0;
            // console.log('keywordLength', keywordLength);
            const distance = keywordLength >= 7 ? keywordLength * 2 : keywordLength * 3;
            return {
              source: keyword,
              target: nodes[0],
              distance: distance,
            };
          });
          // console.log('links', links);

          const data = { nodes, links };

          const wordLength = [];
          for (let i = 0; i < data.nodes.length; i++) {
            const idLength = data.nodes[i].id.length;
            wordLength.push(idLength);
          }

          const width = 350;
          const height = 280;
          const svg = d3.select(svgRef.current).attr('width', width).attr('height', height);

          const link = svg
            .selectAll('line')
            .data(data.links)
            .join('line')
            .attr('stroke', '#DBDBDB')
            .attr('stroke-width', 1);

          const node = svg
            .selectAll('g')
            .data(data.nodes)
            .join('g')
            .on('mouseover', function () {
              d3.select(this).raise(); // 마우스 오버 시 맨 위로 올리기
            })
            .each(function (d, i) {
              const isMainNode = i === 0;

              d3.select(this)
                .append('rect')
                .attr(
                  'width',
                  isMainNode
                    ? wordLength[i % wordLength.length] * 11
                    : wordLength[i % wordLength.length] * 11,
                )
                .attr('height', isMainNode ? 35 : 30)
                .attr('fill', isMainNode ? '#000' : colors[i % colors.length]) // 첫 번째 노드인 경우 네이버색, 그외 흰
                .attr('opacity', isMainNode ? 2 : 1)
                .attr(
                  'x',
                  isMainNode
                    ? (-wordLength[i % wordLength.length] * 11) / 2
                    : (-wordLength[i % wordLength.length] * 11) / 2,
                ) // rect의 시작점을 중앙으로 이동
                .attr('y', isMainNode ? -18 : -15) // rect의 시작점을 중앙으로 이동
                .attr('rx', 10) // x축 반지름
                .attr('ry', 10); // y축 반지름

              d3.select(this)
                .append('text')
                .text(d => d.id)
                .attr('text-anchor', 'middle') // 텍스트를 가운데 정렬
                .attr('alignment-baseline', 'middle') // 텍스트의 기준선을 가운데로 설정
                .attr('fill', isMainNode ? 'white' : 'white') // 텍스트 색상 설정
                .style('font-size', isMainNode ? '12px' : '11px') // 텍스트 크기 설정
                .style('font-family', 'Pretendard-Regular') // 텍스트 글꼴 설정
                .style('pointer-events', 'none'); // 마우스 이벤트 무시

              d3.select(this).raise(d[0]);
            });

          const simulation = d3
            .forceSimulation(data.nodes)
            .force(
              'link',
              d3
                .forceLink(data.links)
                .id(d => d.id)
                .distance(d => (d.distance / 2) * 20),
            )
            .force('charge', d3.forceManyBody().strength(-100)) // 키워드들끼리 밀어내는 힘을 줄임
            .force('center', d3.forceCenter(width / 2, height / 2)); // 중심으로 몰리는 forceCenter 유지

          simulation.force('x', d3.forceX(width / 2).strength(0.5));
          simulation.force('y', d3.forceY(height / 2).strength(0.5));
          simulation.force(
            'radial',
            d3.forceRadial((d, i) => (i === 0 ? 0 : width / 4), width / 2, height / 2).strength(1),
          );

          simulation.on('tick', () => {
            link
              .attr('x1', d => d.source.x)
              .attr('y1', d => d.source.y)
              .attr('x2', d => d.target.x)
              .attr('y2', d => d.target.y);
            node.attr('transform', d => `translate(${d.x}, ${d.y})`);
          });
        }
      } catch (error) {
        console.error('에러 발생:', error);
      }
    };

    fetchData();
  }, [parameter?.data?.param?.item, parameter?.data?.changeIndex]);

  if (badRequest) {
    // console.log('요청많을때');
    return (
      <svg ref={svgRef} width="340px" height="280px">
        <rect width="340px" height="280px" fill="#fff" opacity="0.5" />
        <image
          href={badRequestImg}
          x={(340 - 50) / 2}
          y={(280 - 120) / 2}
          width="50px"
          height="50px"
        />
        <text x={(340 - 150) / 2} y={280 / 2} textAnchor="middle" className="label3">
          <tspan x="50%" dy="1.5em">
            현재 요청이 많습니다.
          </tspan>
          <tspan x="50%" dy="1.5em">
            잠시 뒤 다시 시도해 주세요
          </tspan>
        </text>
      </svg>
    );
  } else if (loging) {
    return <svg ref={svgRef} width="340px" height="280px" />;
  } else {
    // console.log('검색중');
    return (
      <svg ref={svgRef} width="340px" height="280px">
        <rect width="340px" height="280px" fill="#fff" opacity="0.5" />
        <image href={spiner} x={(340 - 70) / 2} y={(280 - 70) / 2} width="70px" height="70px" />
      </svg>
    );
  }
}
