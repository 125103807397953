//basic
import React, { useEffect, useState, useCallback } from 'react';
//api
import { field_keywords_api } from '../../../../apis/field_keywords_api.js';

//external library
import { Text } from '@visx/text';
import { scaleLog } from '@visx/scale';
import Wordcloud from '@visx/wordcloud/lib/Wordcloud';

//components
import { formatDateToYYYYmmdd } from '../../../../utils/dateFunction.js';

/* ───────────────────────────────────────────────────────────────
 * FieldKeywords : 키워드 분류 화면 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

function FieldKeywords(selectedRegion) {
  const today = formatDateToYYYYmmdd(new Date());
  const [fieldData, setFieldData] = useState([]);
  const [keywordList, setKeywordList] = useState([]);

  const division = selectedRegion.selectedRegion;

  // console.log(selectedRegion.selectedRegion);
  // console.log(division);

  const newColor = '#1EB3B8';
  const colors = ['#2C959C', '#5CCBA3', '#96E587', '#81DBD1', newColor];
  const spiralType = 'archimedean'; //archimedean, rectangular
  const withRotation = false;

  const fontSizeSetter = datum => fontScale(datum.value);
  const fixedValueGenerator = () => 0.5;

  const fontScale = scaleLog({
    domain: [Math.min(...fieldData.map(w => w.value)), Math.max(...fieldData.map(w => w.value))],
    range: [10, 50],
  });

  function getRotationDegree() {
    const rand = Math.random();
    const degree = rand > 0.5 ? 60 : -60;
    return rand * degree;
  }

  const handleLoad = useCallback(async () => {
    try {
      const keyOptions = {
        collection_date: today,
        division: division,
      };

      // console.log(keyOptions);

      const result = await field_keywords_api(keyOptions);

      const updatedData = result.map(item => ({
        text: item.keyword,
        value: item.count,
      }));

      setFieldData(updatedData);
      setKeywordList(result);
    } catch (error) {
      console.error('Error loading data:', error);
    }
  }, [today, division]);

  useEffect(() => {
    handleLoad();
  }, [handleLoad]);

  const sortedKeywordList = keywordList
    .sort((a, b) => {
      if (b.count !== a.count) {
        return b.count - a.count;
      }
      return a.keyword.localeCompare(b.keyword);
    })
    .filter((keyword, index, self) => {
      return index === self.findIndex(k => k.keyword === keyword.keyword);
    });

  return (
    <>
      <div className="wordcloud">
        {fieldData && (
          <Wordcloud
            words={fieldData}
            width={340}
            height={200}
            fontSize={fontSizeSetter}
            font="Impact"
            padding={3}
            spiral={spiralType}
            rotate={withRotation ? getRotationDegree : 0}
            random={fixedValueGenerator}
          >
            {cloudWords =>
              cloudWords.map((w, i) => (
                <Text
                  key={i}
                  className={`word size-${w.size}`}
                  fill={colors[i % colors.length]}
                  textAnchor="middle"
                  transform={`translate(${w.x * 0.8}, ${w.y * 0.8}) rotate(${w.rotate})`}
                  fontSize={w.size * 0.8}
                >
                  {w.text}
                </Text>
              ))
            }
          </Wordcloud>
        )}
      </div>

      <div className="nk_title d-between">
        <div className="d-flex">
          <p className="label2">순위</p>
          <p className="label2">키워드</p>
        </div>
        <p className="label2">노출수</p>
      </div>

      <div className="d-flex">
        <ul className="keyword_list">
          {sortedKeywordList.slice(0, 10).map((keyword, index) => (
            <li key={index} className="d-between">
              <div className="d-flex">
                <p className="primary title2">{index + 1}</p>
                <p className="body1">{keyword.keyword}</p>
              </div>
              <p className="body1 count">{keyword.count}</p>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default FieldKeywords;
