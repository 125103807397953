// basic
import { useState, useEffect, useCallback } from 'react';

// api
import { techmeme_api } from '../../../apis/techmeme_api.js';

//common
import { formatDateToYYYYmmdd } from '../../../utils/dateFunction.js';

// css
import '../../../styles/techmeme.css';

// img
import lockImg from '../../../assets/lock_img03.png';

/* ───────────────────────────────────────────────────────────────
 * Invests : 투자 화면 표시 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

function Techmeme() {
  const [techmemeData, setTechmemeData] = useState(null);

  const today = formatDateToYYYYmmdd(new Date()); // YYYYMMDD

  // 테크미미 페이지 열기
  const detailed_page_load = item => {
    const { tech_post_url } = item;
    if (tech_post_url) {
      window.open(tech_post_url, '_blank');
    } else {
      alert('해당 URL이 없습니다.');
    }
  };

  // -- 같은 조건으로 투자, 키워드 api 호출 -- //
  const fetchData = useCallback(async () => {
    const options = { collection_date: today, return_size: 50 }; // 최신순으로 50개만 리턴 받게 함

    try {
      const [techmemeResult] = await Promise.all([techmeme_api(options)]);
      setTechmemeData(techmemeResult);
    } catch (error) {
      console.error('Error techmeme data:', error);
    }
  }, [today]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function formatDate(dateString) {
    // "yyyymmdd"를 "yyyy-mm-dd"로 변경
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    return `${year}.${month}.${day}`;
  }

  return (
    <div>
      <div className="column">
        <div className="scroll">
          {techmemeData && techmemeData.length > 0 ? (
            <div>
              {techmemeData.map(item => (
                <div key={item.id}>
                  <ul className="techmeme_box body1" onClick={() => detailed_page_load(item)}>
                    <li className="body1">{item.tech_media}</li>
                    <li>{item.tech_writer && <p className="lebel1">/{item.tech_writer}</p>}</li>
                    {/* 사람이름이라서 있으면 "/이름" 들어가고 null이면 안나오게함 */}
                    <li className="body2 gray3">
                      {formatDate(item.tech_date)}&nbsp;{item.tech_time}
                    </li>
                    <li className="body1">
                      {item.tech_content_translation || 'translation data null'}
                    </li>
                  </ul>
                </div>
              ))}
            </div>
          ) : (
            <div className="label3 no_data_wrap">
              <img src={lockImg} alt="이미지" />
              수집된 정보가 없습니다.
              <br />
              잠시만 기다려주세요.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Techmeme;
