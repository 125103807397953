// basic
import React, { useState, useEffect, useCallback } from 'react';

// api
import { laws_api } from '../../../apis/laws_api.js';

// contexts
import { useAuth } from '../../../contexts/AuthProvider.js';

// external library
import RegionButtons from '../../common/RegionButtons.js';

// img
import infoCircle from '../../../assets/info-circle.png';
import countryImages from '../../../utils/countryImg';
import lockImg from '../../../assets/lock_img04.png';

/* ───────────────────────────────────────────────────────────────
 * Laws : 법규 화면 표시 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

function Laws() {
  const { user } = useAuth();

  const lawsKeywords = `${user?.result?.laws_keywords_domestic || ''},${
    user?.result?.laws_keywords_overseas || ''
  }`;

  const regionOption = ['국내', '해외'];
  const [selectedRegion, setSelectedRegion] = useState(regionOption[0]);
  const [lawsData, setLawsData] = useState(null);
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    setShowMessage(
      !(lawsData && lawsData.length > 0 && lawsData.some(item => item.division === selectedRegion)),
    );
  }, [lawsData, selectedRegion]);

  const regionData = lawsData ? lawsData.filter(item => item.division === selectedRegion) : [];

  // 해외 데이터 10개만 나오게 하기
  const selectedForeignData = selectedRegion === '해외' ? regionData.slice(-10).reverse() : [];

  // 중복된 아이템의 총 갯수를 계산
  const selectedRegionData = regionData.length > 0 ? regionData : [];
  const countDuplicateItems = value => {
    return selectedRegionData.filter(item => item.index === value).length;
  };

  // 리스트 클릭시 페이지 이동
  const detailed_page_load = item => {
    const { detail_page_url } = item;
    if (detail_page_url) {
      window.open(detail_page_url, '_blank');
    } else {
      alert('URL이 없습니다.');
    }
  };

  // 해외법규 날짜
  const formatDate = dateString => {
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);
    return `${year}.${month}.${day}`;
  };

  const handleRegionChange = region => {
    setSelectedRegion(region);
    // fetchData();
  };

  // -- 같은 조건으로 법규 api 호출 -- //
  const fetchData = useCallback(async () => {
    const options = { division: selectedRegion, keyword: lawsKeywords };

    try {
      const [lawsResult] = await Promise.all([laws_api(options)]);

      setLawsData(lawsResult);
    } catch (error) {
      // Handle errors here
      console.error('Error fetching data:', error);
    }
  }, [selectedRegion, lawsKeywords]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      {/* 국내/해외 */}
      <div className="sm_global_box d-end">
        <RegionButtons
          options={regionOption}
          selectedRegion={selectedRegion}
          onRegionChange={handleRegionChange}
        />
      </div>

      <div className="guide_box">
        <p className="body2 d-center">
          <img src={infoCircle} alt="시계이미지" />
          아이콘을 눌러 국내, 해외 기준 정보를 바꿔보세요.
        </p>
      </div>

      <div className="column">
        <div>
          {selectedRegion === regionOption[0] ? (
            <h1 className="title1">국내 법규 요약</h1>
          ) : (
            <h1 className="title1">해외 법규 요약</h1>
          )}
        </div>

        <div className="NewsSummary Laws scroll">
          {selectedRegionData.length > 0 ? (
            <div>
              {selectedRegionData.map((item, index) => {
                const indexValue = item.index;
                return (
                  <React.Fragment key={item.id}>
                    {/* 국내 법규 */}
                    {selectedRegion === '국내' && (
                      <ul>
                        {indexValue !== selectedRegionData[index - 1]?.index && (
                          <li className="body1 laws_title" style={{ margin: '10px 0' }}>
                            <strong style={{ margin: '0' }}>{indexValue}</strong>
                            <span className="body2" style={{ paddingLeft: '8px' }}>
                              총 {countDuplicateItems(indexValue)}건
                            </span>
                          </li>
                        )}
                        <li className="body2" onClick={() => detailed_page_load(item)}>
                          - {item.title}
                        </li>
                      </ul>
                    )}
                  </React.Fragment>
                );
              })}

              {/* 해외 법규 */}
              <div className="invests_table global_laws_table">
                {selectedRegion === '해외' && (
                  <table>
                    <thead className="body2">
                      <tr>
                        <th>국가</th>
                        <th>제목</th>
                        <th>등록일자</th>
                      </tr>
                    </thead>
                    <tbody className="label2">
                      {selectedForeignData
                        .filter(
                          (item, index, array) =>
                            array.findIndex(i => i.title === item.title) === index,
                        )
                        .map(item => (
                          <tr key={item.id} onClick={() => detailed_page_load(item)}>
                            <td>
                              <img
                                src={countryImages.find(img => img.alt === item.country)?.src}
                                alt={item.country}
                              />
                            </td>
                            <td>{item.title}</td>
                            <td>{formatDate(item.post_date)}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          ) : (
            showMessage && (
              <div className="label3 no_data_wrap">
                <img src={lockImg} alt="이미지" />
                수집된 정보가 없습니다.
                <br />
                법규 키워드를 설정해주세요.
                <br />
                <span className="label4 primary">
                  해당 설정로 수집된 정보가 없다면
                  <br />
                  다음 날 최신 정보를 수집하여 보여 드립니다.
                </span>
              </div>
            )
          )}
        </div>
      </div>

      <div className="line"></div>
    </div>
  );
}

export default Laws;
