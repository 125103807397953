import React, { useState, useEffect } from 'react';

//img
import scroll from '../../assets/scrollTop.png';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      setIsVisible(scrollTop > 50);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    isVisible && (
      <button
        onClick={scrollToTop}
        style={{
          position: 'fixed',
          right: '16px',
          bottom: '85px',
          zIndex: '999',
        }}
      >
        <img src={scroll} alt="스크롤탑 버튼" />
      </button>
    )
  );
};

export default ScrollToTopButton;
