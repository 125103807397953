import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { useAuth } from '../../contexts/AuthProvider';
import { DropDownContainer } from '../settingPage/StyleComponent';
import { toast } from 'react-toastify';

// api
import { stock_codes_api } from '../../apis/settings_api';
import { stock_update } from '../../apis/tsb_setting_api';

const StockSetting = forwardRef((props, ref) => {
  const { user } = useAuth();
  const { id, stock_keywords } = user.result;

  const [hasText, setHasText] = useState(false); //텍스트가 있는지 확인
  const [inputValue, setInputValue] = useState(''); //input 텍스트박스 value 상태

  const [hasOptions, setHasOptions] = useState(false); //자동완성 검색어가 있는지 알려주는 상태
  const [cursorIndex, setCursorIndex] = useState(0); //키보드방향으로 드롭다운 선택을 위한 상태
  const dropdownRef = useRef(); //ul에 대한 ref

  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const [options, setOptions] = useState(''); //자음추출용인데 쓸데없는듯

  const [useStockKeywords, setUseStockKeywords] = useState(stock_keywords);

  useEffect(() => {
    const getStockCodes = async () => {
      const result = await stock_codes_api();
      const optionsMap = result.map(item => ({
        companyName: item.company_name,
        companyCode: item.company_code,
      }));
      setDeselectedOptions(optionsMap);
      setOptions(optionsMap.map(item => `${item.companyCode} ${item.companyName} `));
    };

    getStockCodes();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inputValue === '') {
      setOptions(Object.keys(deselectedOptions));
      setHasText(false);
    } else {
      setHasText(true);
    }

    if (dropdownRef.current) {
      const childrenArr = [...dropdownRef.current.children];
      childrenArr.map((el, idx) => {
        if (cursorIndex === -1) {
          //키보드 위 아래 화살표 제외한 키 클릭했을 경우
          return (el.className = '');
        } else if (idx === cursorIndex % childrenArr.length) {
          return (el.className = 'hover');
        } else {
          return (el.className = '');
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, cursorIndex]);

  // 자음 추출 함수
  const filterOptions = text => {
    const filteredOptions = deselectedOptions.filter(item => {
      const companyNameLowerCase = item.companyName.toLowerCase();
      const companyCodeLowerCase = item.companyCode.toLowerCase();

      return (
        companyNameLowerCase.includes(text.toLowerCase()) ||
        companyCodeLowerCase.includes(text.toLowerCase())
      );
    });

    if (filteredOptions.length !== 0) setHasOptions(true);
    else setHasOptions(false);

    setOptions(filteredOptions);
  };

  const handleInputChange = event => {
    const {
      target: { value },
    } = event;

    const uppercaseValue = value.toUpperCase(); // 입력된 값 대문자로 변환

    if (uppercaseValue.trim().length !== 0) {
      setHasText(true);
    }
    filterOptions(uppercaseValue); // 대문자로 변환된 값 전달
    setInputValue(uppercaseValue); // 대문자로 변환된 값 설정
  };
  // input의 onChange 이벤트 때, 입력값을 inputValue에 저장하고 hasText값 갱신

  const handleDropDownClick = clickedOption => {
    setInputValue(`${clickedOption.companyCode} ${clickedOption.companyName}`);
    filterOptions(`${clickedOption.companyCode} ${clickedOption.companyName}`);
  };

  const keyDownHandler = e => {
    if (e.key === 'ArrowDown') {
      //커서 움직이는 걸 방지하는 용도
      e.preventDefault();
      //아래쪽 방향 키보드를 누르면 index를 +1 해줍니다.
      setCursorIndex(prev => prev + 1);
      // console.log('방향키인덱스:: ', cursorIndex);
      if (dropdownRef.current) {
        const childrenArr = [...dropdownRef.current.children];
        childrenArr.forEach((el, idx) => {
          el.className = idx === cursorIndex % childrenArr.length ? 'hover' : '';
        });
      }
    } else if (e.key === 'ArrowUp') {
      //커서 움직이는 걸 방지하는 용도
      e.preventDefault();
      //위쪽 방향 키보드를 누르면 index를 -1 해줍니다.
      setCursorIndex(prev => Math.abs(prev - 1));
      if (dropdownRef.current) {
        const childrenArr = [...dropdownRef.current.children];
        childrenArr.forEach((el, idx) => {
          el.className = idx === cursorIndex % childrenArr.length ? 'hover' : '';
        });
      }
    } else if (e.key === 'Enter' && cursorIndex !== -1) {
      // 위 아래 방향 키보드를 누르고 enter 클릭 시
      // ul의 자식 요소를 배열로 가지고 옵니다.
      const childrenArr = [...dropdownRef.current.children];
      // console.log(childrenArr);
      //dropdownRef.current.childElementCount === childrenArr.length li의 개수입니다.
      //모듈러 연산자를 통해 cursorIndex 값이 커져도 li 개수 영역 안에서 선택 가능
      //해당 선택한 드롭다운의 텍스트를 가지고 옵니다.
      // const text = childrenArr[cursorIndex % dropdownRef.current.childElementCount].textContent;
      const text = childrenArr[cursorIndex % dropdownRef.current.childElementCount].textContent
        .replace(/\s+/g, ' ')
        .trim();
      // console.log(text);
      //InputValue 값 갱신 + 검색어 자동완성 여부 확인 + cursorIndex 초기화
      setInputValue(text);
      filterOptions(text);
      setCursorIndex(-1);
    } else {
      //키보드 방향 위아래 외 다른 키 눌렀을 경우 cursorIndex 초기화
      setCursorIndex(-1);
    }
  };

  // 삭제 버튼 눌렀을때
  const handleRemove = async (e, keyword, location) => {
    const removedKeyword = keyword;

    try {
      const options = {
        id: id,
        keywordValue: removedKeyword,
        removedKeyword: removedKeyword,
      };
      const result = await stock_update(options);
      setUseStockKeywords(result.result.stock_keywords);
    } catch (error) {
      console.error('Error delete stock keywords:', error);
    }
  };

  const renderKeywords = keywords => {
    return keywords.split(',').map((keyword, index, array) => {
      const handleClick = e => {
        handleRemove(e, keyword);
      };
      return (
        <span key={index} data-span-value={keyword}>
          {keyword}
          <button value="remove" onClick={e => handleClick(e, keyword)} className="remove"></button>
        </span>
      );
    });
  };

  const handleRegister = async () => {
    if (inputValue.trim() === '') {
      alert('종목명 또는 코드를 입력 해 주세요.');
      return;
    }

    const matchResult = inputValue.match(/\d+/);

    if (!matchResult) {
      alert('주식을 선택 후 등록해주세요.');
      return;
    }

    if (!matchResult[0]) {
      setInputValue('');
      // console.log('여기');
      return;
    }

    const trimmedCode = matchResult[0];

    if (deselectedOptions === '' || deselectedOptions === null || deselectedOptions === undefined) {
      alert('키워드 리스트 부재');
      return;
    } //없어도됨

    const isKeywordValid = deselectedOptions.some(option => {
      const companyCodeLowerCase = option.companyCode.toLowerCase();

      return companyCodeLowerCase === trimmedCode;
    });

    console.log(isKeywordValid);

    if (!isKeywordValid) {
      setInputValue('');
      return;
    }

    const currentCount = useStockKeywords.split(',').filter(keyword => keyword !== '');
    if (currentCount.length >= 10) {
      // 10개 이상이면 더 이상 등록하지 못하도록 막음
      alert('최대 10개까지만 등록 가능합니다.');
      return;
    }

    try {
      const options = {
        id: id,
        keywordValue: inputValue,
      };
      const result = await stock_update(options);
      setUseStockKeywords(result.result.stock_keywords);
      toast.success('설정 정보가 저장되었습니다. 저장된 설정은 다음 날부터 적용됩니다.');
    } catch (error) {
      console.error('Error updating stock keywords:', error);
    }
    setInputValue('');
  };

  // Enter키 입력 감지
  const handleOnKeyPress = e => {
    if (e.key === 'Enter') {
      handleRegister();
    }
  };

  return (
    <div className="setting_box search_wrap">
      <div className="setting_intro_title">
        <h1 className="label1 secondary">주식 종목 설정</h1>
        <p className="body2 secondary">
          종목명 또는 종목 코드(최대 10개)로 검색하여 Board 화면에 반영할 주식 종목을 설정합니다.
        </p>
      </div>

      <div className="keyword_box keyword_border" style={{ paddingBottom: '0px' }}>
        <p className="label2">종목명 또는 종목 코드로 찾기</p>
        <div className="pass_input d-between">
          <input
            type="text"
            placeholder="주목해야 할 회사가 있나요?"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={e => {
              keyDownHandler(e);
            }}
            onKeyPress={handleOnKeyPress}
          />
          <button onClick={handleRegister} className="pass_btn">
            등록
          </button>
        </div>

        {hasText && hasOptions && (
          <DropDown
            ref={dropdownRef}
            options={options}
            cursorIndex={cursorIndex}
            handleComboBox={handleDropDownClick}
          />
        )}
      </div>

      <span className="keywords body2 same">
        {useStockKeywords === ''
          ? '현재 등록된 키워드가 없습니다.'
          : renderKeywords(useStockKeywords)}
      </span>
    </div>
  );
});
export default StockSetting;

export const DropDown = React.forwardRef(({ options, handleComboBox }, ref) => {
  return (
    <DropDownContainer ref={ref}>
      {options.map((option, index) => (
        <li
          key={index}
          onClick={() => {
            handleComboBox(option);
          }}
        >
          {option.companyCode} &nbsp; {option.companyName}
        </li>
      ))}
    </DropDownContainer>
  );
});
