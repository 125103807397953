import { BASE_URL } from '../config/base_url';

/* ─────────────────────────────────────────────────────────────
 * field_keywords_api
 * 빅카인즈 수집 데이터 (경제뉴스 주요 키워드)
 * https://www.bigkinds.or.kr/
 * ─────────────────────────────────────────────────────────────
 */

export async function field_keywords_api(options) {
  let query = '';

  if (options) {
    if (options.division && options.division !== '전체') {
      query += `?division=${options.division}&collection_date=${options.collection_date}`;
    } else {
      query += `?collection_date=${options.collection_date}`;
    }
  }
  const response = await fetch(`${BASE_URL}/field-keywords${query}`);

  if (!response.ok) {
    throw new Error('필드 키워드 정보를 불러오는데 실패했습니다.');
  }
  const body = await response.json();

  return body;
}
