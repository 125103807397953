// basic
import React from 'react';

// img
import korea from '../../assets/korea.png';
import global from '../../assets/global.png';

/* ───────────────────────────────────────────────────────────────
 * RegionButtons
 * 국내, 해외 구분 버튼 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

function RegionButtons({ options, selectedRegion, onRegionChange }) {
  return (
    <div className="global_btn_wrap d-center body2 sm_global_wrap">
      <div className="d-center">
        {options.map(region => (
          <React.Fragment key={region}>
            {region === '해외' ? (
              <div
                className={`global_btn sm_global d-flex ${
                  region === selectedRegion ? 'selected' : ''
                }`}
                onClick={() => onRegionChange(region)}
              >
                <img src={global} alt="해외 아이콘 이미지" />
                {region}
              </div>
            ) : (
              <div
                className={`global_btn sm_global d-flex ${
                  region === selectedRegion ? 'selected' : ''
                }`}
                onClick={() => onRegionChange(region)}
              >
                <img src={korea} alt="국내 아이콘 이미지" />
                {region}
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default RegionButtons;
