// basic
import React from 'react';
import { Link } from 'react-router-dom';

// css
import '../../styles/Setting.css';

// img
import SignInOutButton from '../signupPage/SignInOutButton';

/* ───────────────────────────────────────────────────────────────
 * NavigationBar
 * 네브바 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

const NavigationBar = () => {
  const handleClick = () => {
    // 페이지 맨 위로 스크롤
    window.scrollTo(0, 0);
  };

  return (
    <div className="nav_container">
      <Link to="/" onClick={handleClick}>
        <h1 className="title2" style={{ cursor: 'pointer' }}>
          Trend Sensing Board
        </h1>
      </Link>

      <div style={{ padding: '6px 10px' }}>
        <SignInOutButton></SignInOutButton>
      </div>
    </div>
  );
};

export default NavigationBar;
