// basic
import React, { useCallback, useEffect, useState } from 'react';

// api
import GoogleKeyword from './GoogleKeyword';
import GoogleRelatedKeywrod from './GoogleRelatedKeyword.js';
import NaverTrend from './NaverTrend';
import { google_keywords_api } from '../../../apis/google_keywords_api.js';

// css
import '../../../styles/SocialTrend.css';

//img
import naver from '../../../assets/naver_logo.png';
import google from '../../../assets/google.png';

/* ───────────────────────────────────────────────────────────────
 * SocialTrend
 * - 구글 네이버 키워드 보여주는 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

export default function SocialTrend() {
  const [param, setParam] = useState({});
  const [changeIndex, setChangeIndex] = useState(0);

  const fetchData = useCallback(async () => {
    const result = await google_keywords_api();
    const item = result.rss.channel[0];

    setParam(item);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDataFromChild = index => {
    setChangeIndex(index);
  };

  return (
    <div>
      <div className="column">
        <div className="keyword_list">
          <p className="title1" style={{ maxWidth: '100%'}}>
            인기 검색어 TOP 10
          </p>
          <img src={google} alt="아이콘 이미지" />
          <GoogleKeyword data={param} onDataFromChild={handleDataFromChild} />
        </div>
      </div>

      <div className="line"></div>

      <div className="column social_wrap">
        <p className="title1">
          연관 검색어
        </p>
        <img src={google} className="social_img" alt="아이콘 이미지" />
        <GoogleRelatedKeywrod data={{ param, changeIndex }} />
      </div>

      <div className="line"></div>

      <div className="column social_wrap">
        <p className="title1">
          검색량 추이
        </p>
        <img src={naver} className="social_img" alt="아이콘 이미지" />
        <NaverTrend data={{ param, changeIndex }} />
      </div>

      <div className="line"></div>
    </div>
  );
}
