//basic
import React, { useState, useEffect } from 'react';

//common
import { formatDateToYYYYmmdd } from '../../../../utils/dateFunction.js';

// img
import lockImg from '../../../../assets/lock_img01.png';

/* ───────────────────────────────────────────────────────────────
 * MarketSummary
 * 금융 및 시장 요약 내용 표시 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

const MarketSummary = ({ data }) => {
  const today = formatDateToYYYYmmdd(new Date()); // YYYYMMDD
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    if (data && data.length > 0) {
      const hasSummaryData = data.some(item => item.summary_date === today);
      setShowMessage(!hasSummaryData);
    } else {
      setShowMessage(true);
    }
  }, [data, today]);

  // 요약 수집기준 시간 포멧
  const formatCreatedAt = createdAt => {
    const date = new Date(createdAt);
    const formattedDate = `${date.getFullYear()}.${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}.${date.getDate().toString().padStart(2, '0')} ${date
      .getHours()
      .toString()
      .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date
      .getSeconds()
      .toString()
      .padStart(2, '0')}`;

    return formattedDate;
  };

  return (
    <div className="MarketSummary">
      {data && data.length > 0 ? (
        <div>
          {data.map(item => (
            <div key={item.id}>
              <p className="body2 gray3">
                금융 및 시장 현황 요약 {formatCreatedAt(data[0].created_at)} 기준
              </p>
              <div>
                <p
                  className="body1"
                  dangerouslySetInnerHTML={{
                    __html: item.content,
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        showMessage && (
          <div className="label3 no_data_wrap">
            <img src={lockImg} alt="이미지" />
            최신 금융 정보를 요약 중입니다.
          </div>
        )
      )}
    </div>
  );
};

export default MarketSummary;
