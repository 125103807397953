/* ───────────────────────────────────────────────────────────────
 *  InvestsKeyword : 투자 화면 주요 키워드 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

//basic
import React from 'react';

const KeywordsList = ({ keywords }) => {
  const displayKeywordsAndRank = keywords => {
    if (!keywords) {
      return (
        <tr>
          <td />
          <td>키워드를 불러오지 못했습니다.</td>
        </tr>
      );
    }

    const keywordArray = keywords.split(',');

    return (
      <>
        {Array.from({ length: 10 }).map((_, index) => (
          <tr key={index} className="d-flex" style={{ paddingTop: '12px' }}>
            <td className="primary title2">{index + 1}</td>
            <td className="body1 ellipsis">
              {index < keywordArray.length ? keywordArray[index] : '-'}
            </td>
          </tr>
        ))}
      </>
    );
  };

  return (
    <div className="Keywords_list">
      <table>
        <tbody>{displayKeywordsAndRank(keywords)}</tbody>
      </table>
    </div>
  );
};

export default KeywordsList;
