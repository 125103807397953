// basic
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

// context
import { useAuth } from '../../contexts/AuthProvider';

// component
import TsbChatbot from '../common/TsbChatbot';

// css
import '../../styles/Reset.css';
import '../../styles/Common.css';

/* ───────────────────────────────────────────────────────────────
 * App : App.js 에서 element를 App 컴포넌트로 감싸져 있는 라우터는
 * Outlet 컴포넌트를 거쳐서 렌더링 됨
 *
 * useEffcet 안에 auth()를 사용하여 페이지가 변경 될 때마다 유저 인가를 확인.
 * 유저가 확인 되면 유저 정보를 설정하여 Context 값으로 설정 함. (어떤 컴포넌트에서든 꺼내서 사용할 수 있도록 커스텀훅 설정)
 * ───────────────────────────────────────────────────────────────
 */

function App() {
  const auth = useAuth();
  const location = useLocation();

  useEffect(() => {
    // 페이지가 변경될 때마다 useAuth() 실행 // 유저 인가 확인 및 유저 데이터 설정 함.
    if (typeof auth === 'function') {
      auth(); // auth가 함수인 경우에만 실행
    }
  }, [location.pathname, auth]);

  return (
    <div>
      {/* body */}
      <div className="body">
        <Outlet />

        {/* 챗봇 */}
        <TsbChatbot />
      </div>
    </div>
  );
}

export default App;
