import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useAuth } from '../../contexts/AuthProvider';
import { formatDateToYYYYmmdd_ko } from '../../utils/dateFunction';

// Components
import NavigationBar from '../../components/common/NavigationBar';
import IndustryTrend from '../../components/homePage/news&keyword/IndustryTrends';
import MarketStockStatus from '../../components/homePage/market&stock/MarketStockStatus';
import Patent from '../../components/homePage/patent/Patent';
import Legislation from '../../components/homePage/legislation/Legislation';
import Law from '../../components/homePage/law/Laws';
import Invest from '../../components/homePage/invests/Invest';
import Report from '../../components/homePage/report/Report';
import SocialTrend from '../../components/homePage/socialTrend/SocialTrend';
import EventSchedule from '../../components/homePage/eventSchedule/EventSchedule';
import Techmeme from '../../components/homePage/techmeme/techmeme';

// common
import ScrollToTopButton from '../../components/common/ScrollTop.js';

// Images
import Left from '../../assets/arrow_left.png';
import Time from '../../assets/time.png';

function HomePage() {
  const [componentVisibility, setComponentVisibility] = useState({
    showIndustryTrend: true,
    showMarketStockStatus: true,
    showPatent: true,
    showLegislation: false,
    showLaw: false,
    showInvest: false,
    showReport: false,
    showSocialTrend: false,
    showEventSchedule: false,
    showTechmeme: false,
  });

  const { user } = useAuth();
  const { user_company } = user.result;

  const today_ko = formatDateToYYYYmmdd_ko(new Date());

  const toggleComponent = componentName => {
    setComponentVisibility({
      ...componentVisibility,
      [componentName]: !componentVisibility[componentName],
    });
  };

  return (
    <>
      <Helmet>
        <title>Trend Sensing Board</title>
      </Helmet>

      <ScrollToTopButton />

      <div className="nav">
        <NavigationBar />
      </div>

      <div className="setting_page_wrap">
        <div className="user_intro body1">
          <span className="title2">{user_company}</span>님, 안녕하세요.
          <div className="body2 d-flex">
            <img src={Time} alt="시계이미지" style={{ marginRight: '6px' }} />
            {today_ko}
          </div>
        </div>

        <div>
          <ToggleComponent
            label="업계 동향 및 경쟁 정보"
            state={componentVisibility.showIndustryTrend}
            onClick={() => toggleComponent('showIndustryTrend')}
          >
            <IndustryTrend />
          </ToggleComponent>

          <ToggleComponent
            label="금융 및 시장 현황"
            state={componentVisibility.showMarketStockStatus}
            onClick={() => toggleComponent('showMarketStockStatus')}
          >
            <MarketStockStatus />
          </ToggleComponent>

          <ToggleComponent
            label="특허 정보"
            state={componentVisibility.showPatent}
            onClick={() => toggleComponent('showPatent')}
          >
            <Patent />
          </ToggleComponent>

          <ToggleComponent
            label="규제 정보"
            state={componentVisibility.showLegislation}
            onClick={() => toggleComponent('showLegislation')}
          >
            <Legislation />
          </ToggleComponent>

          <ToggleComponent
            label="법규 정보"
            state={componentVisibility.showLaw}
            onClick={() => toggleComponent('showLaw')}
          >
            <Law />
          </ToggleComponent>

          <ToggleComponent
            label="투자 정보"
            state={componentVisibility.showInvest}
            onClick={() => toggleComponent('showInvest')}
          >
            <Invest />
          </ToggleComponent>

          <ToggleComponent
            label="기업 보고서"
            state={componentVisibility.showReport}
            onClick={() => toggleComponent('showReport')}
          >
            <Report />
          </ToggleComponent>

          <ToggleComponent
            label="소셜 트렌드 현황"
            state={componentVisibility.showSocialTrend}
            onClick={() => toggleComponent('showSocialTrend')}
          >
            <SocialTrend />
          </ToggleComponent>

          <ToggleComponent
            label="행사 일정"
            state={componentVisibility.showEventSchedule}
            onClick={() => toggleComponent('showEventSchedule')}
          >
            <EventSchedule />
          </ToggleComponent>

          <ToggleComponent
            label="Techmeme"
            state={componentVisibility.showTechmeme}
            onClick={() => toggleComponent('showTechmeme')}
          >
            <Techmeme />
          </ToggleComponent>
        </div>
      </div>
    </>
  );
}

// 토글 컴포넌트
function ToggleComponent({ label, state, onClick, children }) {
  return (
    <>
      <div className="nav_container link" onClick={onClick}>
        <p className="label1 secondary">{label}</p>
        <img className={state ? 'left_icon' : 'right_icon'} src={Left} alt="아이콘 이미지" />
      </div>
      {state && children}
    </>
  );
}

export default HomePage;
