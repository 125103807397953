// css
import '../../../../styles/Reset.css';
import '../../../../styles/Common.css';

// img
import lockImg from '../../../../assets/lock_img04.png';

/* ───────────────────────────────────────────────────────────────
 * RelatedNewsLoad : 관련 뉴스 리스트 표시하는 컴포넌트
 * 부모 컴포넌트에서 전달받은 item.lable 값으로 뉴스 api 호출해서 화면에 뿌립니다.
 * ───────────────────────────────────────────────────────────────
 */

const NewsLoadMore = ({ data, selectedRegion }) => {
  const pageLoad = data => {
    if (data.detail_page_url) {
      window.open(data.detail_page_url, '_blank');
    } else {
      alert('수집된 뉴스 URL이 없습니다.');
    }
  };

  return (
    <div className="news_related">
      {data ? (
        <ul>
          {data
            .filter(relatedItem => relatedItem.division === selectedRegion)
            // Use slice(1) to exclude the first item
            .slice(0)
            .map((relatedItem, index) => (
              <li key={index} onClick={() => pageLoad(relatedItem)}>
                <p className="label2">
                  {relatedItem.media} &nbsp; &#124; &nbsp;{relatedItem.title}
                </p>
              </li>
            ))}
        </ul>
      ) : (
        <div className="label3 no_data_wrap">
          <img src={lockImg} alt="이미지" />
          수집된 정보가 없습니다.
          <br />
          법규 키워드를 설정해주세요.
          <br />
          <span className="label4 primary">
            해당 설정로 수집된 정보가 없다면
            <br />
            다음 날 최신 정보를 수집하여 보여 드립니다.
          </span>
        </div>
      )}
    </div>
  );
};

export default NewsLoadMore;
