/* ───────────────────────────────────────────────────────────────
 * TagKeyword
 * 키워드를 표시하는 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

const TagKeyword = ({ data, tag }) => {
  const tagValue = data[0][tag]; // data 객체에서 tag에 해당하는 속성값을 추출하여 변수에 저장

  if (!tagValue) {
    return null; // tagValue가 없을 경우 아무것도 렌더링하지 않음
  }

  const tagKeywords = tagValue.split(',');

  return (
    <ul>
      {tagKeywords.map((keyword, index) => (
        <li className="keyword body2" key={index}>
          # {keyword.trim()}
        </li>
      ))}
    </ul>
  );
};

export default TagKeyword;
