/* ───────────────────────────────────────────────────────────────
 * InvestsContent : 투자 화면  테이블 표시 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */

// basic
import React from 'react';

// img
import lockImg from '../../../assets/lock_img03.png';

const InvestsContent = ({ data }) => {
  // 데이터가 널일 때 처리
  if (!data || data.length === 0) {
    return null; // 혹은 다른 처리 방법을 선택할 수 있습니다.
  }

  // service 값을 기준으로 객체를 그룹화하여 중복 제거
  const uniqueServicesMap = data.reduce((accumulator, currentValue) => {
    accumulator[currentValue.service] = currentValue;
    return accumulator;
  }, {});

  // 중복이 제거된 객체들을 배열로 변환
  const uniqueServices = Object.values(uniqueServicesMap);

  return (
    <div className="invests_table">
      {uniqueServices && uniqueServices.length > 0 ? ( // data가 존재하고 비어있지 않을 때 테이블을 렌더링합니다.
        <table>
          <thead className="body2">
            <tr key={uniqueServices.company_description}>
              <th style={{ paddingLeft: '20px' }}>기업</th>
              <th>서비스</th>
              <th>단계</th>
              <th style={{ paddingRight: '20px' }}>금액</th>
            </tr>
          </thead>

          <tbody className="label2">
            {uniqueServices.map(item => (
              <tr key={item.id}>
                <td style={{ paddingLeft: '20px' }}>{item.company}</td>
                <td>{item.service}</td>
                <td>{item.investment_stage}</td>
                <td>{item.investment_amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="label3 no_data_wrap">
          <img src={lockImg} alt="이미지" />
          수집된 정보가 없습니다.
          <br />
          잠시만 기다려주세요.
        </div>
      )}
    </div>
  );
};

export default InvestsContent;
