import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useAuth } from '../../../../contexts/AuthProvider';

const MarketList = ({ data }) => {
  const { user } = useAuth();
  const user_market_keywords = user.result.market_keywords;

  const [selectedMarket, setSelectedMarket] = useState(null);
  const [filteredMarketData, setFilteredMarketData] = useState([]);

  const handleMarketClick = id => {
    setSelectedMarket(id);
  };
  useEffect(() => {
    if (data && Object.keys(data).length > 0 && selectedMarket === null) {
      const marketArray = Object.values(data).flatMap(arr => arr);

      const uniqueMarketData = marketArray.filter((currentMarket, currentIndex, market) => {
        const previousMarketIndex = market.findIndex(
          market => market.expnn_name === currentMarket.expnn_name,
        );
        return currentIndex === previousMarketIndex;
      });

      setFilteredMarketData(uniqueMarketData);

      setSelectedMarket(uniqueMarketData.length > 0 ? uniqueMarketData[0].id : null);
    }
  }, [data, selectedMarket, user_market_keywords]);

  if (!data) {
    return null;
  }

  const selectedMarketData = filteredMarketData.find(item => item.id === selectedMarket);

  if (!selectedMarketData) {
    return null;
  }

  const { expnn_name, graph_img_url, present_price, increase_status, increase_rate, market_date } =
    selectedMarketData;

  //상승 하락 변수
  const increaseStatusText = ['상승', '하락', '보합'];

  // increaseStatus에 따라 아이콘 설정
  let icon = '';

  if (increaseStatusText.indexOf(increase_status) !== -1) {
    if (increaseStatusText.indexOf(increase_status) === 0) {
      icon = '▲';
    } else if (increaseStatusText.indexOf(increase_status) === 1) {
      icon = '▼';
    } else if (increaseStatusText.indexOf(increase_status) === 2) {
      icon = '-';
    }
  }

  // 상승 하락 보합에 따른 클래스명 변경
  let statusClass = 'same';
  if (increase_status === increaseStatusText[0]) {
    statusClass = 'up';
  } else if (increase_status === increaseStatusText[1]) {
    statusClass = 'down';
  }

  //슬라이드
  const sliderSettings = {
    dots: false,
    infinite: false,
    // arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
  };

  return (
    <div className="cont_box">
      <div className="slider-container">
        <Slider {...sliderSettings}>
          {filteredMarketData
            .filter(item => item.increase_status !== null)
            .map(item => (
              <div key={item.id}>
                <button
                  onClick={() => handleMarketClick(item.id)}
                  className={`slide_btn label3 ${selectedMarket === item.id ? 'selected' : ''}`}
                >
                  {item.expnn_name}
                </button>
              </div>
            ))}
        </Slider>
      </div>

      {selectedMarket !== null && (
        <div key={selectedMarket}>
          <div className="colum">
            <p className="label1">{expnn_name}</p>
            <div className="d-flex">
              <h1 className="heading1">
                {present_price.includes('/') ? (
                  present_price.split('/').map((part, index) => (
                    <React.Fragment key={index}>
                      {index > 0 && <br />}
                      {part}
                    </React.Fragment>
                  ))
                ) : (
                  <>
                    {present_price.includes('돈') ? (
                      present_price.replace('돈', '')
                    ) : (
                      <>{present_price}원</>
                    )}
                  </>
                )}
              </h1>
              <p className={`${statusClass} body2`}>
                {icon} {increase_rate}
              </p>
            </div>
          </div>

          <div>
            <div className="stock_img d-center">
              <img src={graph_img_url} alt="시장그래프" />
            </div>

            <span className="body3 d-end gray3">{market_date} 네이버 기준</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default MarketList;
