import { useAuth } from '../../contexts/AuthProvider';
import React, { forwardRef } from 'react';
import { useEffect, useState, useRef } from 'react';
import { DropDownContainer } from '../settingPage/StyleComponent';
import { toast } from 'react-toastify';

// api
import { news_keywords } from '../../apis/tsb_setting_api';
import { insert_latest_keywords_api } from '../../apis/latest_keywords_api';
import { delete_latest_keyword_api } from '../../apis/latest_keywords_api';

//common
import { formatDateToYYmm } from '../../utils/dateFunction';

// css
import '../../styles/Setting.css';

/* ───────────────────────────────────────────────────────────────
 * NewsSetting
 * - 뉴스 키워드로 검색 후 설정 컴포넌트
 * ───────────────────────────────────────────────────────────────
 */
const NewsSetting = forwardRef(({ newsDomestic, newsOverseas }, ref) => {
  const { user } = useAuth();
  const { id, user_email } = user.result;

  const dropdownRef = useRef(); //ul에 대한 ref
  const [isDomesticDropdownView, setDomesticDropdownView] = useState(false);
  const [isOverseasDropdownView, setOverseasDropdownView] = useState(false);

  const [domesticKeyword, setDomesticKeyword] = useState('');
  const [overseasKeyword, setOverseasKeyword] = useState('');

  const [tagKeyword, setTagKeyword] = useState({
    message: '',
    result: {
      news_keywords_domestic: '',
      news_keywords_overseas: '',
    },
  });

  const { news_keywords_domestic, news_keywords_overseas } = tagKeyword.result;

  function chunkArray(array = [], chunkSize) {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  }

  //드롭다운용
  const [newsDomesticChunks, setNewsDomesticChunks] = useState('');
  const [newsOverseasChunks, setNewsOverseasChunks] = useState('');
  useEffect(() => {
    setNewsDomesticChunks(chunkArray(newsDomestic, 10));
  }, [newsDomestic]);

  useEffect(() => {
    setNewsOverseasChunks(chunkArray(newsOverseas, 10));
  }, [newsOverseas]);

  // 인풋 창에 텍스트를 쓸 때마다 값이 저장됨
  const handleDomesticKeywordChange = e => {
    setDomesticKeyword(e.target.value);
  };
  const handleOverseasKeywordChange = e => {
    setOverseasKeyword(e.target.value);
  };

  const handleDomesticDropDownClick = clickedOption => {
    setDomesticKeyword(`${clickedOption.keyword}`);
  };

  const handleOverseasDropDownClick = clickedOption => {
    setOverseasKeyword(`${clickedOption.keyword}`);
  };

  // 등록버튼 클릭 시
  const handleRegisterDomestic = async () => {
    const regex = /^[a-zA-Z0-9ㄱ-ㅎ가-힣 ]+$/; //특수문자 변수

    // 입력값이 없으면 아무 동작 하지 않음
    if (domesticKeyword.trim() === '' || !regex.test(domesticKeyword)) {
      alert('특수문자를 제외한 키워드를 입력 해 주세요.');
      return;
    }

    // 문자열 길이가 28보다 크면 알림창
    if (domesticKeyword.length > 28) {
      alert('키워드는 28자 내외로 입력 해 주세요.');
      return;
    }

    // 기존 키워드 갯수 확인
    const currentDomesticKeywords = news_keywords_domestic
      .split(',')
      .filter(keyword => keyword.trim() !== '');
    if (currentDomesticKeywords.length >= 5) {
      // 5개 이상이면 더 이상 등록하지 못하도록 막음
      alert('최대 5개까지만 등록 가능합니다.');
      return;
    }
    try {
      const result = await news_keywords('domestic', domesticKeyword, id); //등록하는내용
      setTagKeyword(result);
      let params = { user_email: user_email, division: 'news_domestic', keyword: domesticKeyword };
      const reusdd = await insert_latest_keywords_api(params);
      setNewsDomesticChunks(chunkArray(reusdd, 10));
      toast.success('설정 정보가 저장되었습니다. 저장된 설정은 다음 날부터 적용됩니다.');
    } catch (error) {
      console.error('Error updating domestic keywords:', error);
    } finally {
      setDomesticKeyword('');
    }
  };

  const handleRegisterOverseas = async () => {
    const regex = /^[a-zA-Z0-9ㄱ-ㅎ가-힣 ]+$/; //특수문자 변수

    if (overseasKeyword.trim() === '' || !regex.test(overseasKeyword)) {
      alert('특수문자를 제외한 키워드를 입력 해 주세요.');
      return;
    }

    // 문자열 길이가 28보다 크면 알림창
    if (overseasKeyword.length > 28) {
      alert('키워드는 28자 내외로 입력 해 주세요.');
      return;
    }
    const currentOverseasKeywords = news_keywords_overseas
      .split(',')
      .filter(keyword => keyword.trim() !== '');
    if (currentOverseasKeywords.length >= 5) {
      alert('최대 5개까지만 등록 가능합니다.');
      return;
    }
    try {
      const result = await news_keywords('overseas', overseasKeyword, id);
      setTagKeyword(result);
      let params = { user_email: user_email, division: 'news_overseas', keyword: overseasKeyword };
      const saveOversea = await insert_latest_keywords_api(params);
      setNewsOverseasChunks(chunkArray(saveOversea, 10));
      toast.success('설정 정보가 저장되었습니다. 저장된 설정은 다음 날부터 적용됩니다.');
    } catch (error) {
      console.error('Error updating overseas keywords:', error);
    } finally {
      setOverseasKeyword('');
    }
  };

  // Enter키 입력 감지
  const handleDomesticOnKeyPress = e => {
    if (e.key === 'Enter') {
      handleRegisterDomestic();
      setDomesticDropdownView(false);
    }
  };

  const handleOverseasOnKeyPress = e => {
    if (e.key === 'Enter') {
      handleRegisterOverseas();
      setOverseasDropdownView(false);
    }
  };

  // 삭제 버튼 눌렀을때
  const handleRemove = async (e, keyword, location) => {
    const remove = e.target.value;
    const removedKeyword = keyword;

    try {
      const result = await news_keywords(location, removedKeyword, id, remove);
      setTagKeyword(result);
    } catch (error) {
      console.error('Error updating domestic keywords:', error);
    } finally {
      setDomesticKeyword('');
      setOverseasKeyword('');
    }
  };

  // 키워드 삭제 버튼추가
  const renderKeywords = (keywords, location) => {
    return keywords.split(',').map((keyword, index, array) => {
      const handleClick = e => {
        handleRemove(e, keyword, location);
      };
      return (
        <span key={index} data-span-value={keyword}>
          {keyword}
          <button value="remove" onClick={e => handleClick(e, keyword)} className="remove"></button>
        </span>
      );
    });
  };

  const deleteDomesticKeyword = e => {
    const handleClick = async e => {
      let params = { user_email: user_email, division: 'news_domestic', id: e.id };
      const deletereturn = await delete_latest_keyword_api(params);
      setNewsDomesticChunks(chunkArray(deletereturn, 10));
    };

    // 키워드 등록일 표기용
    const date = formatDateToYYmm(new Date(e.created_at));

    return (
      <span key={e.index} data-span-value={e.keyword} className="keywords_drop_wrap">
        <div
          className="d-between"
          onClick={() => {
            handleDomesticDropDownClick(e);
          }}
        >
          <span>{e.keyword}</span>
          <span className="gray3">{date}</span>
        </div>
        <button value="remove" onClick={() => handleClick(e)} className="remove"></button>
        {/* {index < array.length - 1 && ','} */}
      </span>
    );
  };

  const deleteOverseasKeyword = e => {
    const handleClick = async e => {
      let params = { user_email: user_email, division: 'news_overseas', id: e.id };
      const deletereturn = await delete_latest_keyword_api(params);
      setNewsOverseasChunks(chunkArray(deletereturn, 10));
    };

    // 키워드 등록일 표기용
    const date = formatDateToYYmm(new Date(e.created_at));

    return (
      <span key={e.index} data-span-value={e.keyword} className="keywords_drop_wrap">
        <div
          className="d-between"
          onClick={() => {
            handleOverseasDropDownClick(e);
          }}
        >
          <span>{e.keyword}</span>
          <span className="gray3">{date}</span>
        </div>
        <button value="remove" onClick={() => handleClick(e)} className="remove"></button>
      </span>
    );
  };

  const handleDomesticClickContainer = () => {
    setDomesticDropdownView(!isDomesticDropdownView);
  };

  const handleOverseasClickContainer = () => {
    setOverseasDropdownView(!isOverseasDropdownView);
  };

  const handleDomesticBlurContainer = () => {
    setTimeout(() => {
      setDomesticDropdownView(false);
    }, 150);
  };

  const handleOverseasBlurContainer = () => {
    setTimeout(() => {
      setOverseasDropdownView(false);
    }, 150);
  };

  // 페이지가 로드될때 키워드 보이게
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await news_keywords('', '', id);
        setTagKeyword(result);
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <div className="setting_box">
      <div className="setting_intro_title">
        <h1 className="label1 secondary">뉴스 검색 설정</h1>
        <p className="body2 secondary">
          입력한 키워드(각각 최대 5개)와 관련된 뉴스가 Board화면에 나타납니다.
        </p>
      </div>

      <div className="keyword_box keyword_border">
        <p className="label2">국내 뉴스 키워드</p>
        <div className="pass_input d-between">
          <input
            type="text"
            placeholder="국내 뉴스에서 찾아봐야 할 키워드가 있나요?"
            value={domesticKeyword}
            onClick={handleDomesticClickContainer}
            onChange={handleDomesticKeywordChange}
            onKeyPress={handleDomesticOnKeyPress}
            onBlur={handleDomesticBlurContainer}
          />
          <button onClick={handleRegisterDomestic} className="pass_btn">
            등록
          </button>
        </div>

        {isDomesticDropdownView && (
          <DropDown
            ref={dropdownRef}
            options={newsDomesticChunks[0]} //1은 중복제거된값들이 있음
            handleComboBox={handleDomesticDropDownClick}
            deleteLatestKeyword={deleteDomesticKeyword}
          />
        )}

        <span className="keywords body2 secondary">
          {news_keywords_domestic === ''
            ? '현재 등록된 키워드가 없습니다.'
            : renderKeywords(news_keywords_domestic, 'domestic')}
        </span>
      </div>

      <div className="keyword_box keyword_border" style={{ paddingBottom: '0px' }}>
        <p className="label2">해외 뉴스 키워드</p>
        <div className="pass_input d-between">
          <input
            type="text"
            placeholder="해외 뉴스에서 찾아봐야 할 키워드가 있나요?"
            value={overseasKeyword}
            onClick={handleOverseasClickContainer}
            onChange={handleOverseasKeywordChange}
            onKeyPress={handleOverseasOnKeyPress}
            onBlur={handleOverseasBlurContainer}
          />
          <button onClick={handleRegisterOverseas} className="pass_btn">
            등록
          </button>
        </div>
        {isOverseasDropdownView && (
          <DropDown
            ref={dropdownRef}
            options={newsOverseasChunks[0]}
            handleComboBox={handleOverseasDropDownClick}
            deleteLatestKeyword={deleteOverseasKeyword}
          />
        )}

        <span className="keywords body2 secondary">
          {news_keywords_overseas === ''
            ? '현재 등록된 키워드가 없습니다.'
            : renderKeywords(news_keywords_overseas, 'overseas')}
        </span>
      </div>
    </div>
  );
});

export default NewsSetting;

export const DropDown = React.forwardRef(({ options, deleteLatestKeyword }, ref) => {
  if (!options || !Array.isArray(options) || options.length === 0) {
    return null; // 옵션이 없을 경우 컴포넌트를 숨깁니다.
  }

  return (
    <DropDownContainer ref={ref}>
      {options.map((option, index) => (
        <li key={index} className="news_setting_keywords">
          <div className="keywords body2">
            {option.keyword === '' ? '과거키워드없음' : deleteLatestKeyword(option)}
          </div>
        </li>
      ))}
    </DropDownContainer>
  );
});
