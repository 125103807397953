import { BASE_URL } from '../config/base_url';

/* ─────────────────────────────────────────────────────────────
 * market_api
 * 네이버페이 시장지표 데이터 (금융) // 국내
 * https://finance.naver.com/marketindex/
 * ─────────────────────────────────────────────────────────────
 */

export async function market_api(options) {
  let query = `market_collection_date=${options.market_collection_date}`;

  if (options.user_email) {
    query = query
      ? `${query}&user_email=${options.user_email}`
      : `user_email=${options.user_email}`;
  }

  try {
    const response = await fetch(`${BASE_URL}/market_status/?${query}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('데이터를 불러오는 중 에러 발생:', error);
    return [];
  }
}

/* ─────────────────────────────────────────────────────────────
 * market_summary_api
 * 기획재정부 일일경제지표 PDF파일을 GPT4.0에게 질문 후 답변 받은 데이터
 * https://www.moef.go.kr/st/ecnmyidx/TbEconomyIndicatorList.do?bbsId=MOSFBBS_000000000045&menuNo=6010200
 * ─────────────────────────────────────────────────────────────
 */

export async function market_summary_api(options) {
  let query = '';
  if (options) {
    query += `summary_date=${options.market_collection_date}`;
  }
  try {
    const response = await fetch(`${BASE_URL}/market_summary?${query}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('데이터를 불러오는 중 에러 발생:', error);
    return [];
  }
}
